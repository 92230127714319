import { PropsWithChildren } from "react";
import ExtendedInputHTMLAttributes from "./ExtendedInputHTMLAttributes";

export interface CheckboxSetProps extends PropsWithChildren {
  title: string;
}

function Checkbox(props: ExtendedInputHTMLAttributes<HTMLInputElement>) {
  const { id, placeholder, required, ...otherProps } = props;
  const description = props["aria-description"] ?? "";
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={id}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-[--color-primary-600] focus:ring-[--color-primary-600]"
          required={required}
          {...otherProps}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {placeholder} {required && <span className="text-red-700">*</span>}
          {description && <div id="comments-description" className="text-gray-500">{description}</div>}
        </label>
      </div>
    </div>
  )
}

function CheckboxSet(props: CheckboxSetProps) {
  const { title, children } = props;
  return (
    <fieldset>
      <legend className="sr-only">{title}</legend>
      <div className="space-y-5">
        {children}
      </div>
    </fieldset>
  )
}

export default {
  Input: Checkbox,
  Set: CheckboxSet
}