import { useTranslation } from "react-i18next";
import { HorseTask } from "src/api/stable/Booking";
import Button from "src/components/Actions/Button";
import { HorseImage } from "src/components/Stable/HorseImage";
import useClaim from "src/hooks/useClaim";
import { IForm } from "src/hooks/useForm";

export interface HorseTaskHeaderComponentProps {
  horseTask: HorseTask;
  onDelete: () => void;
  form: IForm<HorseTask>
}

export default (props: HorseTaskHeaderComponentProps) => {
  const { horseTask, onDelete, form } = props;
  const { t } = useTranslation();

  const updateClaim = useClaim('TasksUpdate');
  const deleteClaim = useClaim('TasksDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <HorseImage id={form.data.horseId ?? "undefined"} className="h-16 w-16 rounded-full ring-1" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{horseTask.title || t('stable.horseTasks.actions.create')}</h1>
          <div className="text-sm font-medium text-gray-500">
            {horseTask.created && <span>{t('common.fields.created')}: {horseTask.created?.toLocaleString()}</span>}
            {horseTask.updated && <span>, {t('common.fields.updated')}: {horseTask.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {updateClaim && <Button colorName="primary" disabled={form.pending}>{t('common.actions.save')}</Button>}
        {deleteClaim && <Button type="button" colorName="red" onClick={onDelete} disabled={form.pending}>{t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
