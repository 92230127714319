import useForm from "src/hooks/useForm";
import FormInput from "../../components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { AuthClient, EmailChangeConfirmRequest, EmailChangeRequest } from "src/api/access/Auth";
import FormAlert from "src/components/Form/FormAlert";
import Alert from "src/components/Feedback/Alert";
import useUser from "src/hooks/useUser";
import BulletSteps from "src/components/Form/Steps/BulletSteps";
import { useParams } from "react-router-dom";

enum ChangeEmailStep {
  Prompt,
  Confirm,
  Done
}

export interface ChangeEmailPromptFormComponentProps {
  onPrompt: (e: string) => void;
}
export interface ChangeEmailConfirmFormComponentProps {
  onConfirm: () => void;
  email: string;
  token?: string;
}

const ChangeEmailPromptForm = (props: ChangeEmailPromptFormComponentProps) => {
  const { onPrompt } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const user = useUser();

  const form = useForm<EmailChangeRequest>();
  const onSubmit = () => {
    authClient
      .emailChange(form.data)
      .then(_ => onPrompt(form.data.newEmail))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }
  return (
    <>
      <div className=" text-gray-600 text-sm">{t('auth.changeEmail.description')}</div>
      <div className="lg:w-1/2 mt-10">
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...t("auth.errors", { returnObjects: true })
          }}
        />

        <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
          <FormInput.Overlapping value={user?.email} placeholder={t('auth.fields.email')} disabled />
          <FormInput.Overlapping {...form.input('newEmail', 'email', { placeholder: t('auth.fields.newEmail'), autoComplete: 'email', required: true })} />
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[--color-primary-600] px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('auth.changeEmail.sendToken')}
            </button>
          </div>
        </form>
      </div >
    </>
  );
}

const ChangeEmailConfirmForm = (props: ChangeEmailConfirmFormComponentProps) => {
  const { email, token, onConfirm } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const user = useUser();

  const form = useForm<EmailChangeConfirmRequest>({ newEmail: email, token: token } as EmailChangeConfirmRequest);
  const onSubmit = () => {
    authClient
      .emailChangeConfirm(form.data)
      .then(_ => onConfirm())
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <div className=" text-gray-600 text-sm">{t('auth.changeEmail.sentToken')}</div>
      <div className="lg:w-1/2 mt-10">
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...t("auth.errors", { returnObjects: true })
          }}
        />

        <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
          <FormInput.Overlapping value={user?.email} placeholder={t('auth.fields.email')} disabled />
          <FormInput.Overlapping {...form.input('newEmail', 'email', { placeholder: t('auth.fields.newEmail'), autoComplete: 'email', required: true })} />
          <FormInput.Overlapping {...form.input('token', 'text', { placeholder: t('auth.token'), required: true })} />
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[--color-primary-600] px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('auth.changeEmail.action')}
            </button>
          </div>
        </form>
      </div >
    </>
  );
}

const ChangeEmailDoneForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Alert.Success title={t('auth.changeEmail.emailChanged')} noClose />
      </div>
    </>
  );
}


export default () => {
  const { t } = useTranslation();
  const { email, token } = useParams<string>();

  const [step, setStep] = useState(ChangeEmailStep.Prompt);
  const [formEmail, setFormEmail] = useState('');

  useEffect(() => {
    if (email || token) {
      setStep(ChangeEmailStep.Confirm);
    }
  }, [email, token]);

  const onPrompt = (e: string) => {
    setStep(ChangeEmailStep.Confirm);
    setFormEmail(e);
  }

  const onConfirm = () => {
    setStep(ChangeEmailStep.Done);
  }

  const steps = [
    { name: 'Request' },
    { name: 'Confirmation', disabled: step < 1 },
    { name: 'Done', disabled: step < 2 }
  ];

  return (
    <>
      <div>
        <div>
          <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
            {t('auth.changeEmail.header')}
          </h2>
        </div >
        <div className="mt-5 mb-10">
          {step === ChangeEmailStep.Prompt && <ChangeEmailPromptForm onPrompt={onPrompt} />}
          {step === ChangeEmailStep.Confirm && <ChangeEmailConfirmForm email={email || formEmail} token={token} onConfirm={onConfirm} />}
          {step === ChangeEmailStep.Done && <ChangeEmailDoneForm />}
        </div>
        <div className="lg:w-1/2">
          <BulletSteps steps={steps} currentStep={step} onClick={(step, i) => setStep(i)} />
        </div>
      </div>
    </>
  );
}