import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LogoClient } from "src/api/core/Core";
import Alert from "src/components/Feedback/Alert";
import FormImage from "src/components/Form/FormImage";
import FormInput from "src/components/Form/FormInput";
import FormSwitch from "src/components/Form/FormSwitch";
import getCountryName from "src/components/i18n/getCountryName";
import configuration, { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useTenant from "src/hooks/useTenant";


const GeneralSettingsSection = () => {
  const { t, i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const tenant = useTenant();
  const [mobileApp, setMobileApp] = useState(configuration.mobileApp);
  const [newsletter, setNewsletter] = useState(true);
  const [error, setError] = useState(false);

  const logoClient = new LogoClient(apiConfiguration);
  const logoUrl = `${getApiBaseUrl(ConfigurationApis.Core)}/api/v1/logo/download?XTenantId=${tenant}`;
  const [logo, setLogo] = useState<string>(logoUrl);

  const uploadLogo = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onLogoUploadError = () => {
        setError(true);
        setLogo(logo);
        reject();
      }
      const onLogoUploadSuccess = () => {
        setError(false);
        setLogo(`${logo}&${(new Date()).getTime()}`);
        resolve(logo);
      }

      logoClient
        .uploadLogo(undefined, { fileName: file.name, data: file })
        .catch(onLogoUploadError)
        .then(onLogoUploadSuccess);
    }
  );


  return (
    <>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <Alert.Information title={t('panel.settings.general.info')} noClose />
      <dl className="mt-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.owner')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <FormInput.Overlapping
                value={configuration.meta.owner}
                placeholder={t('panel.settings.general.owner')}
                aria-description={t('panel.settings.general.owner')}
                disabled
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.address')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <div className="flex flex-wrap gap-3">
                <div className="w-full">
                  <FormInput.Overlapping
                    value={configuration.meta.address?.address}
                    placeholder={t('address.address.title')}
                    aria-description={t('address.address.description')}
                    disabled
                  />
                </div>
                <div className="w-full">
                  <FormInput.Overlapping
                    value={configuration.meta.address?.extra}
                    placeholder={t('address.extra.title')}
                    aria-description={t('address.extra.description')}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <FormInput.Overlapping
                    value={configuration.meta.address?.postCode}
                    placeholder={t('address.postCode.title')}
                    aria-description={t('address.postCode.description')}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <FormInput.Overlapping
                    value={configuration.meta.address?.city}
                    placeholder={t('address.city.title')}
                    aria-description={t('address.city.description')}
                    disabled
                  />
                </div>
                <div className="w-full">
                  <FormInput.Overlapping
                    value={getCountryName(configuration.meta.address?.countryCode, i18n.resolvedLanguage)}
                    placeholder={t('address.countryCode.title')}
                    aria-description={t('address.countryCode.description')}
                    disabled
                  />
                </div>
              </div>
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.emailAddress.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <FormInput.Overlapping
                value={configuration.meta.emailAddress}
                placeholder={t('panel.settings.general.emailAddress.title')}
                disabled
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.emailSender.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <FormInput.Overlapping
                value={configuration.meta.emailSender}
                placeholder={t('panel.settings.general.emailSender.title')}
                disabled
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.logo.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2 mb-8">
              <FormImage current={logo} onUploadFile={uploadLogo} />
            </div>
          </dd>
        </div>
        <div className="pt-6 hidden">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.mobileApp.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={mobileApp}
                disabled={!configuration.mobileApp}
                onChange={(e) => setMobileApp(e.target.checked)}
                placeholder={t('panel.settings.general.mobileApp.title')}
                aria-description={t('panel.settings.general.mobileApp.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 hidden">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.newsletter.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
                placeholder={t('panel.settings.general.newsletter.title')}
                aria-description={t('panel.settings.general.newsletter.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 hidden">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.signUp.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
                placeholder={t('panel.settings.general.signUp.title')}
                aria-description={t('panel.settings.general.signUp.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 hidden">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.general.integrationApiKeys')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <div className="mb-10">
                <FormInput.Overlapping
                  value={configuration.integrations?.deeplApiKey}
                  placeholder="DeepL Api Key"
                  aria-description="DeepL Api Key"
                  disabled
                />
                <div className="mb-10">
                </div>
                <FormInput.Overlapping
                  value={configuration.integrations?.googleMapsApiKey}
                  placeholder="Google Maps Api Key"
                  aria-description="Google Maps Api Key"
                  disabled
                />
                <div className="mb-10">
                </div>
                <FormInput.Overlapping
                  value={configuration.integrations?.googleAnalyticsApiKey}
                  placeholder="Google Maps Api Key"
                  aria-description="Google Maps Api Key"
                  disabled
                />
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </>
  );
}

export default GeneralSettingsSection;