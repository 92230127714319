import { faMessage } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MessagesClient, MessageTopic } from "src/api/notifications/Notifications";
import useMessagingDispatch from "src/hooks/stores/useMessagingDispatch";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { setActiveTopic } from "src/store/messaging/actions";

export interface MessageToUserButtonProps {
  userId?: string;
}

export default (props: MessageToUserButtonProps) => {
  const { userId } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const dispatch = useMessagingDispatch();

  const onClick = () => {
    if (!userId) return;
    apiClient.findTopicWithUser(userId)
      .then(topic => {
        dispatch(setActiveTopic(topic));
      })
      .catch(error => {
        if (error.status === 404) {
          apiClient.createTopic({
            users: [{ userId: userId }]
          } as MessageTopic)
            .then(createdTopic => {
              dispatch(setActiveTopic(createdTopic));
            })
            .catch(() => { });
        }

      });
  };

  return (
    <div>
      <button type="button" className="inline bg-[--color-primary-500] hover:bg-[--color-primary-700] text-white font-medium h-6 w-6 p-1 rounded-full" onClick={onClick}>
        <FontAwesomeIcon icon={faMessage} className="w-4" />
      </button>
    </div>
  );
}