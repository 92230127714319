import { faBellConcierge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { NotificationType } from "src/api/notifications/Notifications";
import Button from "src/components/Actions/Button";
import useClaim from "src/hooks/useClaim";

import { IForm } from "src/hooks/useForm";

export interface TypeHeaderComponentProps {
  type: NotificationType;
  onDelete: () => void;
  form: IForm<NotificationType>
}

export default (props: TypeHeaderComponentProps) => {
  const { type, onDelete, form } = props;
  const { t } = useTranslation();

  const typesUpdateClaim = useClaim('TypesUpdate');
  const typesDeleteClaim = useClaim('TypesDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <FontAwesomeIcon icon={faBellConcierge} className="h-16 p-3 text-[--color-primary-500]" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{type.name || "New type"}</h1>
          <div className="text-sm font-medium text-gray-500">
            {type.created && <span>{t('common.fields.created')}: {type.created?.toLocaleString()}</span>}
            {type.updated && <span>, {t('common.fields.updated')}: {type.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {typesUpdateClaim && <Button colorName="primary" disabled={form.pending}>{t('common.actions.save')}</Button>}
        {typesDeleteClaim && <Button type="button" colorName="red" onClick={onDelete} disabled={form.pending}>{t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
