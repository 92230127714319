import { useTranslation } from "react-i18next";
import Stat, { StatData } from "src/components/Statistics/Stat";
import { HttpQueryFilter, TrainingsClient } from "src/api/stable/Booking";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import moment from "moment";
import { useEffect, useState } from "react";
import _ from "lodash";

const TrainingStatistics = () => {
  const { t, i18n } = useTranslation();

  const [stats, setStats] = useState<StatData[]>([]);

  const numberFormatter = (v: number) => v.toLocaleString(i18n?.resolvedLanguage);
  const currencyFormatter = (v: number) => v.toLocaleString(i18n?.resolvedLanguage, { style: 'currency', currency: 'pln' });
  const durationFormatter = (v: number) => v > 60 ?
    Math.ceil(v / 60).toLocaleString(i18n?.resolvedLanguage, { style: 'unit', unit: 'hour' }) :
    v.toLocaleString(i18n?.resolvedLanguage, { style: 'unit', unit: 'minute' });

  const apiConfiguration = useApiConfiguration();
  const client = new TrainingsClient(apiConfiguration);

  const getTrainings = () => {
    const currentDate = moment().format("YYYY-MM");
    client.get(
      [{ property: 'Start.ToString()', type: '%', value: currentDate } as HttpQueryFilter],
      undefined,
      1000,
      0,
      undefined,
      undefined
    ).then((response) => {
      const total = response.totalCount || 0;
      if (total === 0) return;
      const income = _.sum(response.items?.map((item) => item.type?.price || 0));
      const totalDuration = _.sum(response.items?.map((item) => moment(item.end).diff(moment(item.start), 'minutes') || 0));
      const distinctRiderIds = _.uniq(response.items?.map((item) => item.riderId)).length || 0;
      const confirmed = response.items?.filter((item) => item.isConfirmed).length || 0;
      const paid = response.items?.filter((item) => item.isPaid).length || 0;
      const upcoming = response.items?.filter((item) => moment(item.start).isAfter(moment())).length || 0;
      const today = response.items?.filter((item) => moment(item.start).isSame(moment(), 'day')).length || 0;
      setStats([
        { name: t('panel.widgets.trainingStatistics.count'), value: response.totalCount || 0, formatter: numberFormatter },
        { name: t('stable.riders.group'), value: distinctRiderIds, formatter: numberFormatter },
        { name: t('stable.trainingTypes.fields.duration'), value: totalDuration, formatter: durationFormatter },
        { name: t('panel.widgets.trainingStatistics.income'), value: income, formatter: currencyFormatter },
        { name: t('panel.widgets.trainingStatistics.confirmed'), value: confirmed, change: confirmed / total * 100, formatter: numberFormatter },
        { name: t('panel.widgets.trainingStatistics.paid'), value: paid, change: paid / total * 100, formatter: numberFormatter },
        { name: t('panel.widgets.trainingStatistics.upcoming'), value: upcoming, change: upcoming / total * 100, formatter: numberFormatter },
        { name: t('panel.widgets.trainingStatistics.today'), value: today, change: today / total * 100, formatter: numberFormatter },
      ])
    });
  }

  useEffect(() => {
    getTrainings();
  }, []);

  if (stats.length === 0) return <></>;

  return (
    <div className="w-full py-3">
      <h2 className="leading-2 text-xl mt-3">{t('panel.widgets.trainingStatistics.header')}</h2>
      <h2 className="leading-2 text-gray-500 text-base mb-5 uppercase">{new Date().toLocaleDateString(i18n.resolvedLanguage, { month: 'long', year: 'numeric' })}</h2>
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
          {stats.map((stat, i) => <Stat key={i} stat={stat} id={i} />)}
        </dl>
      </div>
    </div>
  );
}

export default TrainingStatistics;