/* eslint-disable no-prototype-builtins */
import _, { Dictionary } from "lodash";
import { ChangeEvent, Dispatch, FormEvent, HTMLAttributes, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next";
import ExtendedInputHTMLAttributes from "src/components/Form/ExtendedInputHTMLAttributes";
import Toast from 'src/components/Feedback/Toast';

interface IValidationProblemDetails {
  errors: Dictionary<string[]>;
}

interface IProblemDetails {
  status: number;
}

export type Validation<T> = (event: FormEvent<T>) => Dictionary<string[]>;

export interface IForm<TData> {
  data: TData;
  set: (path: string, value: any) => void; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  setData: Dispatch<SetStateAction<TData>>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  validationData?: Dictionary<string[]>;
  validation: (propertyPath: string) => HTMLAttributes<HTMLElement>;
  setValidation: Dispatch<SetStateAction<Dictionary<string[]> | undefined>>;
  extendedValidation: (propertyPath: string) => ExtendedInputHTMLAttributes<HTMLInputElement>;
  input: (propertyPath: string, type?: string, properties?: ExtendedInputHTMLAttributes<HTMLInputElement>) => ExtendedInputHTMLAttributes<HTMLInputElement>;
  textArea: (propertyPath: string, type?: string, properties?: ExtendedInputHTMLAttributes<HTMLTextAreaElement>) => ExtendedInputHTMLAttributes<HTMLTextAreaElement>;
  select: (propertyPath: string, properties?: ExtendedInputHTMLAttributes<HTMLSelectElement>) => ExtendedInputHTMLAttributes<HTMLSelectElement>;
  catchValidationException: (exception: IValidationProblemDetails) => void;
  catchErrorException: (exception: IProblemDetails) => void;
  catchAnyException: (exception: any, toast?: boolean) => void; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  error: number | undefined;
  pending: boolean;
  setPending: Dispatch<SetStateAction<boolean>>;
  onSubmit: <T>(e: React.FormEvent<T>, callback?: React.FormEventHandler<T> | undefined, validation?: Validation<T> | undefined, setPendingToFalseAtEnd?: boolean | undefined) => void;
  onReset: (e: React.FormEvent, callback?: React.FormEventHandler) => void;
  readOnly: boolean;
  setReadOnly: (v: boolean) => void;
}

function isAnValidationProblemDetails(obj: any): obj is IValidationProblemDetails { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  return 'errors' in obj;
}

function isAnProblemDetails(obj: any): obj is IProblemDetails { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  return 'status' in obj;
}

const useForm = <TData>(initialData: TData = {} as TData): IForm<TData> => {
  const [pending, setPending] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [data, setData] = useState<TData>(initialData);
  const [validationData, setValidation] = useState<Dictionary<string[]>>()
  const [error, setError] = useState<number>();
  const { t } = useTranslation();
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      const { checked } = event.target;
      if (name.endsWith('[]')) {
        const propertyPath = name.replace('[]', '');
        const currentValue = _.get(data, propertyPath) || [];
        if (checked) {
          setData(
            _.set<TData>({ ...data || {} },
              propertyPath,
              currentValue.includes(value) ? currentValue : [...currentValue, value]
            )
          );
        } else {
          setData(
            _.set<TData>({ ...data || {} },
              propertyPath,
              currentValue.filter((v: any) => v !== value) /* eslint-disable-line @typescript-eslint/no-explicit-any */
            )
          );
        }
      } else {
        if (checked) {
          if (value === "true" || value === "yes") {
            setData(_.set<TData>({ ...data || {} }, name, true));
          } else {
            setData(_.set<TData>({ ...data || {} }, name, value));
          }
        }
        else {
          if (value === "true" || value === "yes") {
            setData(_.set<TData>({ ...data || {} }, name, false));
          } else {
            setData(_.set<TData>({ ...data || {} }, name, ''));
          }
        }
      }
    } else {
      if (!name.endsWith('[]')) {
        setData(_.set<TData>({ ...data || {} }, name, value));
      } else {
        setData(_.set<TData>({ ...data || {} }, name.replace('[]', ''), value.split(',').map(v => v.trim())));
      }
    }
  }

  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setData(_.set<TData>({ ...data || {} }, name, value));
  }

  const set = (path: string, value: any) => {  /* eslint-disable-line @typescript-eslint/no-explicit-any */
    _.set<TData>({ ...data || {} }, path, value);
  }

  const onSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setData(_.set<TData>({ ...data || {} }, name, value));
  }
  const catchValidationException = (exception: IValidationProblemDetails, toast: boolean = false) => {
    setValidation(exception.errors);
    if (toast) {
      Toast.error(t("common.status.error"), t("common.form.error"));
    }
  }
  const catchErrorException = (exception: IProblemDetails, toast: boolean = false) => {
    setError(exception.status);
    if (toast) {
      Toast.error(t("common.status.error"), t("common.form.error"));
    }
  }
  const catchAnyException = (exception: any, toast: boolean = false) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (isAnValidationProblemDetails(exception))
      return catchValidationException(exception as IValidationProblemDetails, toast);
    if (isAnProblemDetails(exception))
      return catchErrorException(exception as IProblemDetails, toast);
  }

  const getValidationMessage = (propertyPath: string): string => {
    //let message: string = '';
    const errors = validationData && validationData[propertyPath] || [];
    return errors.map(e => t(`validation.${e}`, { defaultValue: undefined }) ?? e).filter(e => e).join(', ');
  }

  const validation = (propertyPath: string): HTMLAttributes<HTMLElement> => {
    if (!validationData?.hasOwnProperty(propertyPath))
      return { className: 'none' };
    return {
      className: 'text-red-500',
      children: getValidationMessage(propertyPath)
    }
  }

  const extendedValidation = (propertyPath: string): ExtendedInputHTMLAttributes<HTMLInputElement> => {
    const props: ExtendedInputHTMLAttributes<HTMLInputElement> = {};
    if (_.has(validationData, propertyPath)) {
      props.isInvalid = true;
      props.validationFeedback = getValidationMessage(propertyPath);
    }
    return props;
  }

  const select = (propertyPath: string, properties: ExtendedInputHTMLAttributes<HTMLSelectElement> = {}): ExtendedInputHTMLAttributes<HTMLSelectElement> => {
    const props: ExtendedInputHTMLAttributes<HTMLSelectElement> = {};
    props.name = propertyPath;
    props.onChange = onSelect;
    props.readOnly = readOnly || pending;
    props.id = propertyPath;
    props.value = _.get(data, propertyPath);
    if (_.has(validationData, propertyPath)) {
      props.isInvalid = true;
      props.validationFeedback = getValidationMessage(propertyPath);
    }
    props.readOnly = pending;
    return { ...properties, ...props };
  }

  const input = (propertyPath: string, type = "text", properties: ExtendedInputHTMLAttributes<HTMLInputElement> = {}): ExtendedInputHTMLAttributes<HTMLInputElement> => {
    const props: ExtendedInputHTMLAttributes<HTMLInputElement> = {};
    props.type = type;
    props.name = propertyPath;
    props.onChange = onChange;
    props.readOnly = readOnly || pending;
    if (["number", "text", "email", "textarea", "input", "select"].includes(type)) {
      props.id = propertyPath;
      props.value = _.get(data, propertyPath);
    } else if (type === "checkbox") {
      if (propertyPath.endsWith('[]')) {
        const purePropertyPath = propertyPath.replace('[]', '');
        props.id = `${propertyPath}_${properties.value}`;
        const currentValue = _.get(data, purePropertyPath) || [];
        props.checked = currentValue.includes(properties.value);
        props.value = properties.value;
      } else {
        props.id = `${propertyPath}_${properties.value}`;
        const currentValue = _.get(data, propertyPath);
        props.value = properties.value;
        props.checked = String(currentValue) == properties.value;
      }
    }
    if (_.has(validationData, propertyPath)) {
      props.isInvalid = true;
      props.validationFeedback = getValidationMessage(propertyPath);
    }
    props.readOnly = pending;
    return { ...properties, ...props };
  }

  const textArea = (propertyPath: string, type = "textarea", properties: ExtendedInputHTMLAttributes<HTMLTextAreaElement> = {}): ExtendedInputHTMLAttributes<HTMLTextAreaElement> => {
    const props: ExtendedInputHTMLAttributes<HTMLTextAreaElement> = {};
    props.type = type;
    props.name = propertyPath;
    props.onChange = onChangeTextArea;
    props.readOnly = readOnly || pending;
    if (["number", "text", "email", "textarea", "input", "select"].includes(type)) {
      props.id = propertyPath;
      props.value = _.get(data, propertyPath);
    }
    if (_.has(validationData, propertyPath)) {
      props.isInvalid = true;
      props.validationFeedback = getValidationMessage(propertyPath);
    }
    props.readOnly = pending;
    return { ...properties, ...props };
  }

  const onSubmit = <T>(
    e: React.FormEvent<T>,
    callback: React.FormEventHandler<T> | undefined = undefined,
    validation: Validation<T> | undefined = undefined,
    setPendingToFalseAtEnd: boolean | undefined = false
  ) => {
    if (pending) {
      console.warn('Currently form is pending, cannot submit');
      e.preventDefault();
      return;
    }
    setValidation(undefined);
    setError(undefined);
    if (validation) {
      const validationFromCallback = validation(e);
      if (_.keys(validationFromCallback).length > 0) {
        setValidation(validationFromCallback);
        e.preventDefault();
        return;
      }
    }
    setPending(true);
    if (callback) {
      callback(e);
    }
    e.preventDefault();
    if (setPendingToFalseAtEnd) {
      setPending(false);
    }
  }

  const onReset = (e: React.FormEvent, callback?: React.FormEventHandler) => {
    setValidation(undefined);
    setError(undefined);
    setData(initialData);
    if (callback) {
      callback(e);
    }
  }

  return {
    data: data,
    set,
    setData: setData,
    onChange,
    validation,
    extendedValidation,
    validationData,
    setValidation,
    input,
    textArea,
    select,
    catchValidationException,
    catchErrorException,
    catchAnyException,
    error,
    pending,
    setPending,
    onSubmit,
    onReset,
    readOnly,
    setReadOnly
  }
}

export default useForm;