import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import useLocalizedNavigate from "src/hooks/useNavigate";
import useToken from "src/hooks/useToken";
import { clearToken } from "src/store/application/actions";

export default () => {
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();
  const token = useToken();

  useEffect(() => {
    if (token) applicationDispatch(clearToken());
    else navigate('/');
  }, [token])

  return (<>
    <Helmet>
      <title>{t('auth.signOut.header')}</title>
    </Helmet>
  </>);
}