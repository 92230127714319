import { HTMLAttributes } from "react"

export default (props: HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="182.2"
      height="182.2"
      version="1.1"
      viewBox="0 0 48.207 48.207"
      xmlSpace="preserve"
      {...props}
    >
      <g transform="translate(-6.873 -7.338)">
        <path
          fill="#000"
          fillOpacity="1"
          fillRule="evenodd"
          strokeWidth="0.392"
          d="M17.874 38.785a13.064 13.064 0 004.577 5.537 15.24 15.24 0 0017.037.036 13.087 13.087 0 004.663-5.573c2.286-5.263 1.788-14.394-.722-19.61-.655-1.357-1.494-2.486-2.843-4.863-1.102-1.874-1.616-3.588.322-5.173a8.628 8.628 0 015.388-1.8c1.832.055 2.957 1.334 3.585 2.985.42 1.09.882 3.274.588 4.451-.145.6-.455.784-.647 1.177-.282.572.035 1.157.463 1.988 6.432 12.55 4.314 27.367-6.228 34.046-6.722 4.259-16.33 4.663-23.531 1.392-12.75-5.797-15.75-21.935-8.82-35.45.423-.831.74-1.416.458-1.988-.192-.392-.498-.557-.647-1.177-.29-1.176.173-3.36.588-4.451.628-1.635 1.753-2.914 3.585-2.973a8.628 8.628 0 015.389 1.8c1.937 1.57 1.423 3.279.34 5.189-1.344 2.377-2.184 3.506-2.843 4.863-2.51 5.2-3.004 14.33-.721 19.61zm-1.227-26.626a1.294 1.294 0 11-1.294 1.294 1.29 1.29 0 011.294-1.294zm14.346 37.168a1.435 1.435 0 11-1.431 1.435 1.431 1.431 0 011.431-1.435zm16.08-7.573a1.435 1.435 0 11-1.432 1.431 1.431 1.431 0 011.432-1.431zm-32.12 0a1.435 1.435 0 11-1.432 1.431 1.435 1.435 0 011.432-1.431zm34.81-10.237a1.431 1.431 0 11-1.435 1.432 1.431 1.431 0 011.435-1.432zm-37.497 0a1.431 1.431 0 11-1.45 1.432 1.431 1.431 0 011.434-1.432zm36.03-10.24a1.435 1.435 0 11-1.435 1.436 1.435 1.435 0 011.435-1.436zm-34.563 0a1.435 1.435 0 11-1.435 1.436 1.435 1.435 0 011.435-1.436zm31.595-9.118a1.294 1.294 0 11-1.295 1.294 1.29 1.29 0 011.295-1.294z"
          className={`cls-1 ${props.className?.split(' ').filter(c => c.startsWith('fill')).join(' ')}`}
        ></path>
      </g>
    </svg>
  )
}