import { createRef, FC, useEffect, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Instructor, InstructorsClient } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import TranslationForm from './Form/Translation';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import Tabs, { Tab } from 'src/components/Tabs/Pills';
import Trainings from './Trainings';
import TrainingTypesForm from './Form/TrainingTypes';
import RidersForm from './Form/Riders';
import HorsesForm from './Form/Horses';
import AbsencesForm from './Form/Absences';
import Tasks from './Tasks';
import Calendar from './Calendar';
import { User, UsersClient } from 'src/api/access/Authority';
import WorkingHoursForm from './Form/WorkingHours';
import _ from 'lodash';
import { FormImageComponentRef } from 'src/components/Form/FormImage';
import { getApiBaseUrl, ConfigurationApis } from 'src/config/config';
import useTenant from 'src/hooks/useTenant';
import { useUploader } from 'src/hooks/useUploader';

const InstructorsForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new InstructorsClient(apiConfiguration);
  const userApiClient = new UsersClient(apiConfiguration);
  const form = useForm<Instructor>();
  const addUserForm = useForm<User>();
  const authGuard = useAuthGuard('/panel/', ['InstructorsRead']);
  const InstructorsUpdateClaim = useClaim('InstructorsUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const tenant = useTenant();

  const photoUrl = `${getApiBaseUrl(ConfigurationApis.Auth)}/api/v1/avatars/${id}?XTenantId=${tenant}&${(new Date()).getTime()}`;
  const uploadAvatar = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onAvatarUploadError = (e: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
        console.error(e);
        reject();
      }
      const onAvatarUploadSuccess = () => {
        const url = `${photoUrl}&${(new Date()).getTime()}`;
        resolve(url);
        if (id === 'create') {
          navigate(`/panel/stable/instructors/`);
        }
      }

      userApiClient
        .uploadAvatar(form.data.userId!, undefined, { fileName: file.name, data: file })
        .catch(onAvatarUploadError)
        .then(onAvatarUploadSuccess);
    }
  );
  const uploader = useUploader(uploadAvatar);
  const uploaderRef = createRef<FormImageComponentRef>();

  const tabs: Tab[] = [
    { id: 'data', name: 'common.data' },
    { id: 'assignments', name: 'stable.booking.assignments' },
    { id: 'workingHours', name: 'stable.booking.workingHours' },
    { id: 'absences', name: 'stable.trainings.absences', visible: false },
    { id: 'trainings', name: 'stable.trainings.group', visible: false },
    { id: 'tasks', name: 'stable.tasks.group', visible: false },
    { id: 'calendar', name: 'ui.calendar.calendar', visible: false }
  ];
  const [currentTab, setCurrentTab] = useState<string>("");

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/instructors/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    const data: Instructor = { ...form.data } as Instructor;
    if (data.workingHours && data.workingHours.values) {
      const workingHoursNew = { id: data.workingHours.id, values: {} };
      _.map(data.workingHours.values, (v, k: string) => {
        _.set(workingHoursNew, `values.${k}`, { start: v.start, end: v.end });
      });
      _.set(data, 'workingHours', workingHoursNew)
    }
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      if (addUserForm.data.email && addUserForm.data.givenName && addUserForm.data.surname) {
        const addUserData = { ...addUserForm.data, userName: addUserForm.data.email } as User;
        if (addUserData.password === '') {
          delete addUserData.password;
        }
        userApiClient.create(addUserData)
          .then((userResponse) => {
            apiClient.create({ ...data, userId: userResponse.id } as Instructor)
              .then((instructorResponse) => {
                Toast.success(t("common.status.success"), t("common.form.saved"));
                form.setData({ ...instructorResponse } as Instructor);
              })
              .catch(e => addUserForm.catchAnyException(e, true))
              .finally(() => form.setPending(false));
          })
          .catch(e => form.catchAnyException(e, true))
          .finally(() => form.setPending(false));
      } else {
        apiClient.create(data)
          .then(() => {
            Toast.success(t("common.status.success"), t("common.form.saved"));
            navigate(`/panel/stable/instructors/`);
          })
          .catch(e => form.catchAnyException(e, true))
          .finally(() => form.setPending(false));
      }
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.instructors.group", href: "/panel/stable/instructors/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/stable/instructors/${id}` }
      ])
    );
    if (id !== 'create') uploader?.setPhoto(photoUrl);
  }, [id]);

  useEffect(() => {
    console.log(id, form.data.id, uploaderRef.current?.file);
    if (id === 'create' && form.data.id !== undefined) {
      if (uploaderRef.current?.file) {
        uploaderRef.current?.upload();
      } else {
        navigate(`/panel/stable/instructors/`);
      }
    }
  }, [form.data.id]);

  useEffect(() => {
    form.setReadOnly(!InstructorsUpdateClaim);
  }, [InstructorsUpdateClaim]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header Instructor={form.data} onDelete={onDelete} form={form} />
        <Tabs tabs={tabs.map(t => ({ ...t, visible: form.data.id ? true : t.visible } as Tab))} currentTab={currentTab} setCurrentTab={setCurrentTab} className="mt-3" />
        {currentTab == 'data' && <>
          <GeneralForm id={id} form={form} addUserForm={addUserForm} uploader={uploader} uploaderRef={uploaderRef} />
          <TranslationForm form={form} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </>}
        {currentTab == 'assignments' && <>
          <HorsesForm form={form} />
          <TrainingTypesForm form={form} />
          <RidersForm form={form} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </>}
        {currentTab == 'absences' && <AbsencesForm form={form} />}
        {currentTab == 'workingHours' && <WorkingHoursForm form={form} />}
        {currentTab == 'trainings' && <Trainings form={form} />}
        {currentTab == 'tasks' && <Tasks form={form} />}
        {currentTab == 'calendar' && <Calendar form={form} />}
      </form >
    </>
  )
}

export default InstructorsForm;