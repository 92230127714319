import { HTMLAttributes, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Horse, HorseTranslation, Instructor, InstructorTranslation, Place, PlaceTranslation, Training, TrainingType, TrainingTypeTranslation, TrainingsClient } from 'src/api/stable/Booking';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faCircle, faEdit, faPlusCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import moment from 'moment';
import Avatar from 'src/components/User/Avatar';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import TrainingTypeSelect from 'src/components/Stable/TrainingTypeSelect';
import _ from 'lodash';
import RiderSelect from 'src/components/Stable/RiderSelect';
import InstructorSelect from 'src/components/Stable/InstructorSelect';
import HorseSelect from 'src/components/Stable/HorseSelect';
import PlaceSelect from 'src/components/Stable/PlaceSelect';
import { TrainingTypeImage } from 'src/components/Stable/TrainingTypeImage';
import { HorseImage } from 'src/components/Stable/HorseImage';

interface TrainingFilters {
  url?: string;
  title?: string;
  typeIds?: string[];
  riderIds?: string[];
  instructorIds?: string[];
  horseIds?: string[];
  placeIds?: string[];
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<TrainingFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.typeIds?.length) f.push({ property: 'TypeId.ToString()', value: form.data.typeIds.join(','), type: 'in' });
  if (form.data.riderIds?.length) f.push({ property: 'RiderId.ToString()', value: form.data.riderIds.join(','), type: 'in' });
  if (form.data.instructorIds?.length) f.push({ property: 'InstructorId.ToString()', value: form.data.instructorIds.join(','), type: 'in' });
  if (form.data.horseIds?.length) f.push({ property: 'HorseId.ToString()', value: form.data.horseIds.join(','), type: 'in' });
  if (form.data.placeIds?.length) f.push({ property: 'PlaceId.ToString()', value: form.data.placeIds.join(','), type: 'in' });
  return f;
}

function renderFiltersForm(form: IForm<TrainingFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <TrainingTypeSelect
          {...form.input("typeIds", "select", { placeholder: t('stable.trainingTypes.group') })}
          value={form.data.typeIds}
          onChange={(types) => form.setData({ ...form.data, typeIds: _.keys(types) })}
          multiple
        />
        <RiderSelect
          {...form.input("riderIds", "select", { placeholder: t('stable.riders.group') })}
          value={form.data.riderIds}
          onChange={(types) => form.setData({ ...form.data, riderIds: _.keys(types) })}
          multiple
        />
        <InstructorSelect
          {...form.input("instructorIds", "select", { placeholder: t('stable.instructors.group') })}
          value={form.data.instructorIds}
          onChange={(types) => form.setData({ ...form.data, instructorIds: _.keys(types) })}
          multiple
        />
        <HorseSelect
          {...form.input("horseIds", "select", { placeholder: t('stable.horses.group') })}
          value={form.data.horseIds}
          onChange={(types) => form.setData({ ...form.data, horseIds: _.keys(types) })}
          multiple
        />
        <PlaceSelect
          {...form.input("placeIds", "select", { placeholder: t('stable.places.group') })}
          value={form.data.placeIds}
          onChange={(types) => form.setData({ ...form.data, placeIds: _.keys(types) })}
          multiple
        />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const TrainingsTable = () => {
  const { i18n, t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Training>>();
  const updateClaim = useClaim('TrainingsUpdate');
  const deleteClaim = useClaim('TrainingsDelete');
  const authGuard = useAuthGuard('/panel/', ['TrainingsRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  const placeTranslation = useEntityTranslation<Place, PlaceTranslation>();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastTraining() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/TrainingsHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Training) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const onClickIsConfirmed = (entity: Training) => {
    if (entity?.id) {
      apiClient.update(entity.id, { ...entity, isConfirmed: entity.isConfirmed ? null : true } as Training)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.updated"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.update")));
    }
  }

  const onClickIsPaid = (entity: Training) => {
    if (entity?.id) {
      apiClient.update(entity.id, { ...entity, isPaid: entity.isPaid ? null : true } as Training)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.updated"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.update")));
    }
  }

  const columns: ITableColumn<Training>[] = [
    { label: t('common.fields.number'), selector: row => row.number, id: "number" },
    { label: t('stable.trainingTypes.item'), selector: row => <div className="flex"><TrainingTypeImage id={row.typeId!} className="h-8 w-8 rounded-full ring-1" /><span className="mt-1 ml-2">{trainingTypeTranslation.getCurrentTranslation(row.type)?.name}</span></div>, id: "type.translations.name" },
    { label: t('stable.horses.item'), selector: row => <div className="flex"><HorseImage id={row.horseId!} className="h-8 w-8 rounded-full ring-1" /><span className="mt-1 ml-2">{horseTranslation.getCurrentTranslation(row.horse)?.name}</span></div>, id: "horse.translations.name" },
    { label: t('stable.instructors.item'), selector: row => <div className="flex"><Avatar userId={row.instructor?.userId} className="h-8 w-8 rounded-full ring-1" /><span className="mt-1 ml-2">{instructorTranslation.getCurrentTranslation(row.instructor)?.name}</span></div>, id: "instructor.translations.name" },
    { label: t('stable.riders.item'), selector: row => <div className="flex"><Avatar userId={row.rider?.userId} className="h-8 w-8 rounded-full ring-1" /><span className="mt-1 ml-2">{row.rider?.user?.fullName}</span></div>, id: "rider.user.fullName" },
    { label: t('stable.places.item'), selector: row => placeTranslation.getCurrentTranslation(row.place)?.name, id: "place.translations.name" },
    { label: t('stable.trainings.fields.isConfirmed'), selector: row => <BoolIcon value={row.isConfirmed} className="h-6 cursor-pointer" onClick={() => onClickIsConfirmed(row)} />, id: "isConfirmed" },
    { label: t('stable.trainings.fields.isPaid'), selector: row => <BoolIcon value={row.isPaid} className="h-6 cursor-pointer" onClick={() => onClickIsPaid(row)} />, id: "isPaid" },
    { label: t('stable.trainings.fields.start'), selector: row => row.start?.toLocaleString(), isOrderable: true, id: "start" },
    { label: t('stable.trainings.fields.end'), selector: row => row.end?.toLocaleString(), isOrderable: true, id: "end" },
    { label: t('stable.trainingTypes.fields.duration'), selector: row => Intl.NumberFormat(i18n.resolvedLanguage, { style: "unit", unit: "minute" }).format(moment.duration(moment(row.end).diff(moment(row.start))).asMinutes()), id: "id" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={`/panel/booking/trainings/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.trainings.group", href: "/panel/booking/trainings/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <h1 className="leading-1 text-2xl">{t('stable.trainings.group')}</h1>
        </div>
        <div className="">
          {updateClaim &&
            <LocalizedLink to="/panel/booking/trainings/create">
              <Button colorName="emerald" className="px-5 py-2">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('stable.trainings.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<Training>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
    </>
  )
}

export interface BoolIconComponentProps extends HTMLAttributes<HTMLDivElement> {
  value?: boolean;
}

export const BoolIcon = (props: BoolIconComponentProps) => {
  const { value, ...otherProps } = props;
  return (
    <>
      <div {...otherProps}>
        {value === true && <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-600 w-full h-full" />}
        {value === false && <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 w-full h-full" />}
        {(value === undefined || value === null) && <FontAwesomeIcon icon={faCircle} className="text-gray-300 w-full h-full" />}
      </div>
    </>
  )
}

export default TrainingsTable;