import { useTranslation } from "react-i18next";
import { Role } from "src/api/access/Authority"
import Button from "src/components/Actions/Button";
import useClaim from "src/hooks/useClaim";
import { IForm } from "src/hooks/useForm";
import { RoleForm } from "./Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersCrown } from "@fortawesome/pro-duotone-svg-icons";


export interface RoleHeaderComponentProps {
  role: Role;
  onDelete: () => void;
  form: IForm<RoleForm>
}

export default (props: RoleHeaderComponentProps) => {
  const { role, onDelete, form } = props;
  const { t } = useTranslation();

  const rolesUpdateClaim = useClaim('RolesUpdate');
  const rolesDeleteClaim = useClaim('RolesDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <FontAwesomeIcon icon={faUsersCrown} className="h-16 p-3 text-[--color-primary-500]" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{role.name || "New role"}</h1>
          <div className="text-sm font-medium text-gray-500">
            {role.created && <span>{t('common.fields.created')}: {role.created?.toLocaleString()}</span>}
            {role.updated && <span>, {t('common.fields.updated')}: {role.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {rolesUpdateClaim && <Button colorName="primary" disabled={form.pending}>{t('common.actions.save')}</Button>}
        {rolesDeleteClaim && role.name !== 'Administrator' && <Button type="button" colorName="red" onClick={onDelete} disabled={form.pending}>{t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
