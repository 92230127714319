import useForm from "src/hooks/useForm"
import FormInput from "../../components/Form/FormInput"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { AuthClient, SignInRequest } from "src/api/access/Auth";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { useEffect } from "react";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { setToken } from "src/store/application/actions";
import useToken from "src/hooks/useToken";
import FormAlert from "src/components/Form/FormAlert";
import Alert from "src/components/Feedback/Alert";
import classNames from "src/components/Utilities/classNames";
import LocalizedLink from "src/components/Router/LocalizedLink";
import useLocalizedNavigate from "src/hooks/useNavigate";
import Button from "src/components/Actions/Button";
import Spinner from "src/components/Feedback/Spinner";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import useEventTracking from "src/hooks/useEventTracking";
import { Helmet } from "react-helmet";


export default () => {

  const form = useForm<SignInRequest>();
  const { t } = useTranslation();
  const token = useToken();

  const navigate = useLocalizedNavigate();
  const applicationDispatch = useApplicationDispatch();

  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const signInTracking = useEventTracking('Form Submit', 'Submitted Sign In', 'Sign In');

  const onSubmit = () => {
    signInTracking();
    authClient
      .signIn(form.data)
      .then(t => applicationDispatch(setToken(t)))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title>{t('auth.signIn.action')}</title>
      </Helmet>
      <div>
        <h2 className="mt-8 text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.signIn.slogan')}
        </h2>
        <div className="mt-2 text-sm leading-6 text-gray-500">
          {t('auth.notAMember')}{' '}
          <LocalizedLink to="/auth/sign-up" className="font-medium text-[--color-primary-600] hover:text-[--color-primary-500]">
            {t('auth.signUp.slogan')}
          </LocalizedLink>
        </div>
      </div>

      <FormAlert
        code={form.error}
        errorMessages={{
          ...t("common.errors", { returnObjects: true }),
          ...t("auth.errors", { returnObjects: true })
        }}
      />

      {token && <Alert.Success title="Signed in, redirecting to user panel" />}

      <div className={classNames([
        'mt-10',
        token && 'hidden'
      ])}>
        <div>
          <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">

            <FormInput.Overlapping
              {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'username', required: true })}
              icon={<FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            />
            <FormInput.Overlapping
              {...form.input('password', 'password', { placeholder: t('auth.fields.password'), autoComplete: 'current-password', required: true })}
              icon={<FontAwesomeIcon icon={faLock} className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-[--color-primary-600] focus:ring-[--color-primary-600]"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                  {t('auth.rememberMe')}
                </label>
              </div>

              <div className="text-sm leading-6">
                <LocalizedLink to="/auth/reset-password" className="font-medium text-[--color-primary-600] hover:text-[--color-primary-500]">
                  {t('auth.forgotPassword')}
                </LocalizedLink>
              </div>
            </div>

            <div>
              <Button colorName="primary" className="w-full justify-center" disabled={form.pending}>
                {t('auth.signIn.header')}
              </Button>
              {form.pending && <Spinner />}
            </div>
          </form>
        </div >
      </div >
    </>
  )
}