import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LocalizedLink from "src/components/Router/LocalizedLink";

export default function Error() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>{t('common.errors.500')}</title>
      </Helmet>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <div className="text-base font-medium text-[--color-primary-600]">500</div>
          <h1 className="mt-4 text-3xl font-medium tracking-tight text-gray-900 sm:text-5xl">{t('common.errors.500')}</h1>
          <div className="mt-6 text-base leading-7 text-gray-600">{t('common.errors.descriptions.500')}</div>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <LocalizedLink
              to="/"
              className="rounded-md bg-[--color-primary-600] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('common.errors.actions.goBackHome')}
            </LocalizedLink>
            <LocalizedLink to="/" className="text-sm font-medium text-gray-900">
              {t('common.errors.actions.contactSupport')} <span aria-hidden="true">&rarr;</span>
            </LocalizedLink>
          </div>
        </div>
      </main>
    </>
  )
}