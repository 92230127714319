import { useEffect } from 'react';
import { isAndroid, isIOS } from "react-device-detect";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import Alert from 'src/components/Feedback/Alert';
import config from 'src/config/config';
import useTenant from 'src/hooks/useTenant';
import useUser from 'src/hooks/useUser';

const Download = () => {
  const appleApp = 'capsigo/id1547746310';
  const androidApp = 'com.tetraz.capsigo';
  const domain = window.location.origin;
  const { t, i18n } = useTranslation();
  const tenant = useTenant();
  const user = useUser();
  const deepLink = user ? `redini://${tenant}:${user?.email}` : `redini://${tenant}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (config.mobileApp) {
      if (isAndroid) {
        window.location.replace(deepLink); setTimeout(function () {
          window.location.replace(`https://play.google.com/store/apps/details?id=${androidApp}`);
        }, 2000);
      } else if (isIOS) {
        window.location.replace(deepLink); setTimeout(function () {
          window.location.replace(`https://apps.apple.com/app/${appleApp}`);
        }, 2000);
      }
    }
  }, []);

  if (!config.mobileApp) {
    return (
      <div className="max-w-7xl mx-auto my-8">
        <Alert.Error title="App not supported" noClose />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.download.header')}</title>
      </Helmet>
      <div className="max-w-7xl mx-auto my-8">
        <div className="md:flex gap-8">
          <QRCode value={`${domain}/${i18n.resolvedLanguage}/download`} className="min-w-96 w-1/2 mx-auto my-auto md:mx-0" bgColor="transparent" fgColor="black" />
          <div className="px-8 lg:px-0">
            <h1 className="text-3xl text-gray-400 py-5 border-b border-gray-200">{t('ui.download.header')}</h1>
            <h2 className="text-5xl text-[--color-primary-700] pt-5">{t('ui.download.slogan')}</h2>
            <div className="text-lg text-gray-500 py-5 mb-5 border-b border-gray-200">{t('ui.download.description')}</div>
            <div className="flex gap-5">
              <img className="h-16 p-2" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Download on the App Store" title="Download on the App Store" />
              <img className="h-16" src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png" alt="Get it on Google Play" title="Get it on Google Play" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;