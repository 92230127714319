import { Menu, Transition } from "@headlessui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next";
import _ from "lodash";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export interface MenuItemComponentProps {
  to: string;
  label: string;
}

const MenuButton = (props: MenuItemComponentProps) => {
  const { to, label } = props;
  const { t } = useTranslation();
  return (
    <Menu.Item>
      <LocalizedLink to={to} className="block px-5 py-3 text-sm leading-6 text-gray-900 hover:bg-[--color-primary-400] active:bg-[--color-primary-700] hover:text-white">
        {t(label)}
      </LocalizedLink>
    </Menu.Item>
  )
}

const StableMenu = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onMouseEnter = () => setOpen(true);
  const onMouseLeave = () => setOpen(false);

  return (
    <Menu as="div" className="relative mr-auto" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Menu.Button className="-m-1.5 flex items-center">
        <span className="sr-only">{t('userMenu.header')}</span>
        <span className="hidden lg:flex lg:items-center">
          <span className="ml-12 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
            {t('stable.section.header')}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={open}
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-hidden">
          <MenuButton to="/p/3b2dfdee-8726-497e-9b4e-eff6a24e3da4/" label='stable.about' />
          <MenuButton to="/stable/horses/" label='stable.horses.group' />
          <MenuButton to="/stable/training-types/" label='stable.trainingTypes.group' />
          <MenuButton to="/stable/instructors/" label="stable.instructors.group" />
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default StableMenu;