
import { useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useUser from 'src/hooks/useUser';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Horse, HorseTranslation, Instructor, TrainingType, RiderTrainingsClient, Training } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import ContentParse from 'src/components/Cms/ContentParse';
import Spinner from 'src/components/Feedback/Spinner';
import { HorseImage } from 'src/components/Stable/HorseImage';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';

export interface HorsesComponentProps {
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
  onSelect: (value?: Horse) => void;
  onGoBack: () => void;
}

const Horses = (props: HorsesComponentProps) => {
  const { type, instructor, onSelect, onGoBack } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const trainingsClient = new RiderTrainingsClient(apiConfiguration);
  const [horses, setHorses] = useState<Horse[]>([]);

  const user = useUser();
  const [horse, setHorse] = useState<string | undefined>(props.horse?.id);
  const [loading, setLoading] = useState(false);
  const entityTranslation = useEntityTranslation<Horse, HorseTranslation>();

  useEffect(() => {
    setLoading(true);
    trainingsClient
      .horses({ typeId: type!.id, instructorId: instructor?.id } as Training)
      .then(response => setHorses(response.items ?? []))
      .finally(() => setLoading(false));
  }, []);

  const options = [...[{
    id: 'any',
    value: undefined,
    image: <HorseImage id={"any"} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: t('stable.horses.any.label'),
    description: <ContentParse>{t('stable.horses.any.description')}</ContentParse>,
  }
  ], ...horses.map((horse) => ({
    id: horse.id,
    value: horse.id,
    image: <HorseImage id={horse.id!} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: entityTranslation.getCurrentTranslation(horse)?.name,
    description: <ContentParse>{entityTranslation.getCurrentTranslation(horse)?.description}</ContentParse>,
    disabled: !horse.isAvailableForGuest && user === undefined
  }) as GridSelectOption)];

  const onSubmit = () => {
    onSelect(horses.find(t => t.id == horse));
  }

  return (
    <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0">
      {loading && <LoadingOverlay />}
      <div className="flex justify-between mb-8 lg:mb-0">
        <div>
          <h2 className="text-3xl text-gray-300 mb-5">{t('stable.trainings.booking.header')}</h2>
        </div>
        <div className="flex">
          <Button type="button" colorName="gray" className="mt-4 px-5 py-1 border-gray-900" onClick={onGoBack}>
            <FontAwesomeIcon icon={faChevronLeft} className="inline h-4" />
          </Button>
          <Button type="button" onClick={onSubmit} colorName="primary" className="mt-4 px-5 py-1 border-[--color-primary-900]" disabled={!horse}>
            {t('ui.steps.nextStep')}
            <FontAwesomeIcon icon={faChevronRight} className="inline h-4" />
          </Button>
        </div>
      </div>
      <h2 className="text-2xl">{t('stable.trainings.booking.selectHorse')}</h2>
      <div className="border-y border-y-gray-200 py-5 my-5">
        {typeof horses === 'undefined' && <Spinner className="mx-auto h-8" />}
        <GridSelect options={options} value={horse} onChange={(e) => setHorse(e.target.value)} full />
      </div>
      <div className="flex justify-between mb-8">
        <div>
        </div>
        <div>
          <Button type="button" onClick={onSubmit} colorName="primary" className="px-5 py-1 border-[--color-primary-900]" disabled={!horse}>
            {t('ui.steps.nextStep')}
            <FontAwesomeIcon icon={faChevronRight} className="inline h-4" />
          </Button>
        </div>
      </div >
    </div >
  )
}

export default Horses;