import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { DetailedGrid, DetailedGridItem } from 'src/components/Data/DetailedGrid';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { Instructor, InstructorTranslation, InstructorsClient } from 'src/api/stable/Stable';
import { useEffect, useState } from 'react';
import Spinner from 'src/components/Feedback/Spinner';
import Avatar from 'src/components/User/Avatar';
import ContentParse from 'src/components/Cms/ContentParse';
import SectionHeader from 'src/components/Typography/SectionHeader';
import SectionDescription from 'src/components/Typography/SectionDescription';
import Page from 'src/components/Cms/Page';

const Instructors = () => {
  const enityTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  const apiConfiguration = useApiConfiguration();
  const instructorsClient = new InstructorsClient(apiConfiguration);
  const [instructors, setInstructors] = useState<Instructor[] | undefined>(undefined);

  useEffect(() => {
    instructorsClient
      .get(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setInstructors(response.items ?? []));
  }, []);

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <SectionHeader><Page id="c7627b7d-ccf9-4311-9ab7-d2140d093021" title raw /></SectionHeader>
          <SectionDescription color><Page id="c7627b7d-ccf9-4311-9ab7-d2140d093021" content raw /></SectionDescription>
        </div>
        {typeof instructors === 'undefined' && <Spinner className="mx-auto h-8" />}
        <div className="text-gray-700">
          <DetailedGrid>
            {instructors?.map((instructor) => <DetailedGridItem key={instructor.id || ""} id={instructor.id || ""} image={<Avatar userId={instructor.userId!} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />} label={enityTranslation.getCurrentTranslation(instructor)?.name || ""}><ContentParse>{enityTranslation.getCurrentTranslation(instructor)?.description}</ContentParse></DetailedGridItem>)}
          </DetailedGrid>
        </div>
      </div>
    </div>
  )
}

export default Instructors;