import { useTranslation } from "react-i18next";
import classNames from "../Utilities/classNames";
import { createRef, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";

export interface CalendarEvent {
  id: string;
  label: string;
  start: Date;
  end: Date;
  colorName: string;
  disabled?: boolean;
  active?: boolean;
  visible?: boolean;
}

export interface AvailableHours {
  start: number[];
  end: number[];
}

export interface CalendarWeekViewComponentProps {
  startCalendar: Date;
  startHour: number;
  endHour: number;
  events?: CalendarEvent[];
  availableHours?: AvailableHours[];
  availableTerms?: AvailableTerm[];
  onClickEvent?: (event: CalendarEvent) => void;
  onClickTerm?: (start: Date, end: Date) => void;
  hideTerms?: boolean;
  className?: string;
  termInterval?: number;
  termGranulation?: number;
}

export interface AvailableTerm {
  start: Date;
  end: Date;
}

interface MobileDayHeaderComponentProps {
  onClick: () => void;
  isCurrent: boolean;
  date: Date;
}

const MobileDayHeader = (props: MobileDayHeaderComponentProps) => {
  const { date, isCurrent, onClick } = props;
  const { i18n } = useTranslation();
  return (
    <button type="button" className="flex flex-col items-center pb-3 pt-2" onClick={onClick}>
      {date.toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short' })}
      {!isCurrent && <span className="mt-1 flex h-8 w-8 items-center justify-center font-medium text-gray-900">
        {date.toLocaleDateString(i18n.resolvedLanguage, { day: '2-digit' })}
      </span>}
      {isCurrent && <span className="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-[--color-primary-600] font-medium text-white">
        {date.toLocaleDateString(i18n.resolvedLanguage, { day: '2-digit' })}
      </span>}
    </button>
  );
}

const CalendarWeekView = (props: CalendarWeekViewComponentProps) => {
  const { hideTerms, startCalendar, startHour, endHour, events, onClickEvent, onClickTerm, availableHours, availableTerms, className, termGranulation, termInterval } = props;
  const container = createRef<HTMLDivElement>();
  const containerNav = createRef<HTMLDivElement>();
  const containerOffset = createRef<HTMLDivElement>();

  const days = _.range(0, 7).map((d) => {
    const date = new Date(startCalendar);
    date.setDate(startCalendar.getDate() + d);
    return date;
  });

  const [mobileDay, setMobileDay] = useState(days.findIndex(d => d.getDate() === (new Date()).getDate()) ?? days[0]);
  const isInDays = (date: Date) => days.some(d => date.getDate() === d.getDate() && date.getMonth() === d.getMonth());
  const isInMobileDay = (date: Date) => days[mobileDay].getDate() === date.getDate() && days[mobileDay].getMonth() === date.getMonth();

  const hours = _.range(startHour, endHour).map(h => new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate(), h, 0, 0));

  const hoursRange = [startHour, endHour];

  const minDate = moment(new Date()).add(1, 'hour').toDate();
  const terms: AvailableTerm[] = !hideTerms ? (availableTerms || (availableHours ? days.map((day) => {
    const dayHours = availableHours[day.getDay()];
    if (!dayHours) return [];
    const dayStart = new Date(day);
    dayStart.setHours(dayHours.start[0], dayHours.start[1], 0, 0);
    const dayEnd = new Date(day);
    dayEnd.setHours(dayHours.end[0], dayHours.end[1], 0, 0);
    return _.range(dayHours.start[0], dayHours.end[1] != 0 ? dayHours.end[0] + 1 : dayHours.end[0])
      .filter(hour => new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, 59, 59) > minDate)
      .map((hour) => {
        return _.range(0, 60, termGranulation ?? 30).map((minute) => {
          const termStart = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, minute, 0);
          const termEnd = moment(termStart).add(termInterval, 'm').toDate();
          return { start: termStart, end: termEnd };
        })
          .map(hour => ({ ...hour, intervalEnd: moment(hour.start).add(termInterval, 'm').toDate() }))
          .filter(term => term.start >= dayStart && term.intervalEnd <= dayEnd);
      });
  }).flat().flat() : []))
    .filter(term => !events?.some(e => moment(e.start).isBefore(term.end) && moment(e.end).isAfter(term.start))) : [];

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60
    if (!container.current) return;
    if (!containerNav.current) return;
    if (!containerOffset.current) return;
    container.current.scrollTop =
      ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
        currentMinute) /
      1440
  }, []);

  return (
    <div ref={container} className={`isolate flex flex-auto flex-col overflow-auto bg-red-50 relative ${className}`}>
      <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
        >
          <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            {days.map((day, i) => <MobileDayHeader key={i} date={day} onClick={() => setMobileDay(i)} isCurrent={mobileDay === i} />)}
          </div>

          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14" />
            {days.map(d => <DayHeader key={d.getTime()} date={d} />)}
          </div>
        </div>
        <div className="md:hidden flex-auto">
          <div className="sticky top-0 backdrop-blur-sm left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 row-start-1 grid divide-y divide-gray-100"
              style={{ gridTemplateRows: `repeat(${hours.length}, minmax(5rem, 1fr))` }}
            >
              <div ref={containerOffset} className="row-end-1 h-10"></div>
              {hours.map((h, i) => <TimelineColumnRow key={i} time={h} />)}
            </div>

            {/* Available terms */}
            <ol
              className="col-start-1 row-start-1 grid grid-cols-1"
              style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
            >
              {terms.filter(term => isInMobileDay(term.start)).map((term, i) => <AvailableTermInput key={i} start={term.start} end={term.end} onClick={onClickTerm} duration={termInterval} hours={hoursRange} isMobile />)}
            </ol>

            {/* Events */}
            <ol
              className="col-start-1 row-start-1 grid grid-cols-1"
              style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
            >
              {events?.filter(event => event.visible !== false).filter(event => isInMobileDay(event.start)).map((event, i) => <Event key={i} event={event} onClick={onClickEvent} hours={hoursRange} isMobile />)}
            </ol>
          </div>
        </div>
        <div className="hidden md:flex flex-auto">
          <div className="sticky top-0 backdrop-blur-sm left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
              style={{ gridTemplateRows: `repeat(${hours.length}, minmax(5rem, 1fr))` }}
            >
              <div ref={containerOffset} className="row-end-1 h-10"></div>
              {hours.map((h, i) => <TimelineColumnRow key={i} time={h} />)}
            </div>

            {/* Vertical lines */}
            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-8" />
            </div>

            {/* Available terms */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
            >
              {terms.map((term, i) => <AvailableTermInput key={i} start={term.start} end={term.end} onClick={onClickTerm} hours={hoursRange} duration={termInterval} />)}
            </ol>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
            >
              {events?.filter(event => event.visible !== false).filter(event => isInDays(event.start)).map((event, i) => <Event key={i} event={event} hours={hoursRange} onClick={onClickEvent} />)}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AvailableTermInputComponentProps {
  start: Date;
  end: Date;
  onClick?: (start: Date, end: Date) => void;
  isMobile?: boolean;
  duration?: number;
  hours: number[];
}

const AvailableTermInput = (props: AvailableTermInputComponentProps) => {
  const { start, end, onClick, isMobile, duration, hours } = props;
  const { i18n } = useTranslation();

  const eventWeekDay = isMobile ? 1 : start.getDay() == 0 ? 7 : start.getDay();

  const startSpan = Math.floor((start.getHours() - hours[0]) * 12 + start.getMinutes() / 5) + 2;
  const durationSpan = Math.ceil((end.getTime() - start.getTime()) / 1000 / 300);

  const endDisplay = new Date(start.getTime() + (duration ?? 15) * 60000);

  const onClickListElement = () => onClick && onClick(start, end);

  return (
    <li className="bg-emerald-50 relative mt-px mr-px cursor-pointer ring ring-emerald-50" style={{ gridRow: `${startSpan} / span ${durationSpan}`, gridColumn: `${eventWeekDay}` }
    } onClick={onClickListElement} >
      <div className="bg-emerald-100 hover:bg-emerald-200 active:bg-emerald-300 text-emerald-600 text-xs w-full rounded-lg px-3 py-1.5 h-full">
        {start.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'short' })}, <span className="font-medium">{start.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })} - {endDisplay.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })}</span>
      </div>
    </li >
  )
}

interface DayHeaderComponentProps {
  date: Date;
}

const DayHeader = (props: DayHeaderComponentProps) => {
  const { date } = props;
  const todayDate = new Date();
  const isToday = date.getDate() === todayDate.getDate() && date.getMonth() === todayDate.getMonth() && date.getFullYear() === todayDate.getFullYear();

  return (
    <div className="flex items-center justify-center py-3">
      <WeekDayName weekDay={date.getDay()} format="short" />
      <span
        className={classNames(
          "font-medium ml-3",
          !isToday && (date.getDay() !== 0 ? "text-gray-900" : "text-red-500"),
          isToday && "ml-3 h-8 w-8 flex items-center justify-center rounded-full bg-[--color-primary-600] text-white"
        )}
      >
        {date.getDate()}
      </span>
    </div>
  );
}

interface TimelineColumnRowComponentProps {
  time: Date;
}

const TimelineColumnRow = (props: TimelineColumnRowComponentProps) => {
  const { time } = props;
  const { i18n } = useTranslation();
  return (
    <div>
      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
        {time.toLocaleTimeString(i18n.resolvedLanguage, { hour: '2-digit', minute: '2-digit' })}
      </div>
    </div>
  );
}

interface EventComponentProps {
  event: CalendarEvent;
  onClick?: (event: CalendarEvent) => void;
  isMobile?: boolean;
  hours: number[];
}

const Event = (props: EventComponentProps) => {
  const { event, onClick, isMobile, hours } = props;
  const { i18n } = useTranslation();

  const eventWeekDay = isMobile ? 1 : event.start.getDay() == 0 ? 7 : event.start.getDay();

  const start = Math.round((event.start.getHours() - hours[0]) * 12 + event.start.getMinutes() / 5 + 2);
  const duration = Math.round((event.end.getTime() - event.start.getTime()) / 1000 / 300);
  const endDisplay = new Date(event.end.getTime() + 1000);

  const onClickListElement = () => onClick && onClick(event);

  return (
    <li className="relative mt-px flex" style={{ gridRow: `${start} / span ${duration}`, gridColumn: `${eventWeekDay}` }} onClick={onClickListElement}>
      <div
        className={classNames(
          `group absolute inset-1 flex flex-col overflow-hidden rounded-lg bg-${event.colorName}-200 p-2 text-xs leading-5 hover:bg-${event.colorName}-300`,
          event.active && "cursor-pointer",
          event.disabled && "cursor-not-allowed"
        )}
      >
        <div className={`order-1 font-medium text-${event.colorName}-700`}>{event.label}</div>
        <div className={`text-${event.colorName}-500 group-hover:text-${event.colorName}-700 text-xs`}>
          <time dateTime="2022-01-12T07:30">{event.start.toLocaleString(i18n.resolvedLanguage, { timeStyle: 'short', dateStyle: 'short' })} - {endDisplay.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })}</time>
        </div>
      </div>
    </li >
  )
}

interface WeekDayNameComponentProps {
  weekDay: number;
  format?: "long" | "short" | "narrow" | undefined;
}

const WeekDayName = (props: WeekDayNameComponentProps) => {
  const { weekDay, format } = props;
  const { i18n } = useTranslation();
  const date = new Date(Date.UTC(2017, 0, 1 + weekDay));
  return <>{date.toLocaleDateString(i18n.resolvedLanguage, { weekday: format || "long" })}</>;
}

export default CalendarWeekView;