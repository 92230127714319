import useForm from "src/hooks/useForm";
import FormInput from "../../components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { AuthClient, PasswordChangeRequest } from "src/api/access/Auth";
import FormAlert from "src/components/Form/FormAlert";
import Alert from "src/components/Feedback/Alert";
import { Dictionary } from "lodash";


export interface ChangePasswordFormComponentProps {
  onChange: () => void;
}

export interface PasswordChangeRequestForm extends PasswordChangeRequest {
  repeatPassword: string;
}

const ChangePasswordForm = (props: ChangePasswordFormComponentProps) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);

  const form = useForm<PasswordChangeRequestForm>();
  const onSubmit = () => {
    authClient
      .passwordChange(form.data)
      .then(_ => onChange())
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    if (form.data.newPassword !== form.data.repeatPassword) {
      result["repeatPassword"] = ["MustBeTheSame"];
    }
    return result;
  }

  return (
    <>
      <div>
        <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.changePassword.header')}
        </h2>
      </div >
      <div className="mt-5 text-gray-600 text-sm">{t('auth.changePassword.description')}</div>
      <div className="lg:w-1/2 mt-10">
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...{ '400': 'Incorrect password' }
          }}
        />

        <form onSubmit={e => form.onSubmit(e, onSubmit, validate)} className="space-y-6">
          <FormInput.Overlapping {...form.input('oldPassword', 'password', { placeholder: t('auth.fields.oldPassword'), required: true })} />
          <FormInput.Overlapping {...form.input('newPassword', 'password', { placeholder: t('auth.fields.newPassword'), required: true })} />
          <FormInput.Overlapping {...form.input('repeatPassword', 'password', { placeholder: t('auth.fields.repeatPassword'), required: true })} />
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[--color-primary-600] px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('auth.changePassword.action')}
            </button>
          </div>
        </form>
      </div >
    </>
  );
}

const ChangePasswordDone = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.changePassword.header')}
        </h2>
      </div >
      <div className="mt-10">
        <div>
          <Alert.Success title={t('auth.changePassword.passwordChanged')} noClose />
        </div >
      </div >
    </>
  );
}

export default () => {
  const [done, setDone] = useState(false);
  const onChange = () => setDone(true);

  if (done) return <ChangePasswordDone />
  return <ChangePasswordForm onChange={onChange} />;

}