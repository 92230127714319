import { HTMLAttributes, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { UserCoupon, UserCouponsClient } from 'src/api/stable/Booking';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faCircle, faEdit, faPlusCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';
import RiderSelect from 'src/components/Stable/RiderSelect';
import InstructorSelect from 'src/components/Stable/InstructorSelect';
import HorseSelect from 'src/components/Stable/HorseSelect';
import PlaceSelect from 'src/components/Stable/PlaceSelect';

interface UserCouponFilters {
  url?: string;
  title?: string;
  typeIds?: string[];
  riderIds?: string[];
  instructorIds?: string[];
  horseIds?: string[];
  placeIds?: string[];
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<UserCouponFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.typeIds?.length) f.push({ property: 'TypeId.ToString()', value: form.data.typeIds.join(','), type: 'in' });
  if (form.data.riderIds?.length) f.push({ property: 'RiderId.ToString()', value: form.data.riderIds.join(','), type: 'in' });
  if (form.data.instructorIds?.length) f.push({ property: 'InstructorId.ToString()', value: form.data.instructorIds.join(','), type: 'in' });
  if (form.data.horseIds?.length) f.push({ property: 'HorseId.ToString()', value: form.data.horseIds.join(','), type: 'in' });
  if (form.data.placeIds?.length) f.push({ property: 'PlaceId.ToString()', value: form.data.placeIds.join(','), type: 'in' });
  return f;
}

function renderFiltersForm(form: IForm<UserCouponFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <RiderSelect
          {...form.input("riderIds", "select", { placeholder: t('stable.riders.group') })}
          value={form.data.riderIds}
          onChange={(types) => form.setData({ ...form.data, riderIds: _.keys(types) })}
          multiple
        />
        <InstructorSelect
          {...form.input("instructorIds", "select", { placeholder: t('stable.instructors.group') })}
          value={form.data.instructorIds}
          onChange={(types) => form.setData({ ...form.data, instructorIds: _.keys(types) })}
          multiple
        />
        <HorseSelect
          {...form.input("horseIds", "select", { placeholder: t('stable.horses.group') })}
          value={form.data.horseIds}
          onChange={(types) => form.setData({ ...form.data, horseIds: _.keys(types) })}
          multiple
        />
        <PlaceSelect
          {...form.input("placeIds", "select", { placeholder: t('stable.places.group') })}
          value={form.data.placeIds}
          onChange={(types) => form.setData({ ...form.data, placeIds: _.keys(types) })}
          multiple
        />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const UserCouponsTable = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new UserCouponsClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<UserCoupon>>();
  const updateClaim = useClaim('CouponsUpdate');
  const deleteClaim = useClaim('CouponsDelete');
  const authGuard = useAuthGuard('/panel/', ['CouponsRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastUserCoupon() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Booking)}/hubs/UserCouponsHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: UserCoupon) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<UserCoupon>[] = [
    { label: t('common.fields.title'), selector: row => row.title, isOrderable: false, id: "title" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={`/panel/booking/user-coupons/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.coupons.group", href: "/panel/booking/user-coupons/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <h1 className="leading-1 text-2xl">{t('stable.coupons.group')}</h1>
        </div>
        <div className="">
          {updateClaim &&
            <LocalizedLink to="/panel/booking/user-coupons/create">
              <Button colorName="emerald" className="px-5 py-2">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('stable.coupons.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<UserCoupon>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
    </>
  )
}

export interface BoolIconComponentProps extends HTMLAttributes<HTMLDivElement> {
  value?: boolean;
}

export const BoolIcon = (props: BoolIconComponentProps) => {
  const { value, ...otherProps } = props;
  return (
    <>
      <div {...otherProps}>
        {value === true && <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-600 w-full h-full" />}
        {value === false && <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 w-full h-full" />}
        {(value === undefined || value === null) && <FontAwesomeIcon icon={faCircle} className="text-gray-300 w-full h-full" />}
      </div>
    </>
  )
}

export default UserCouponsTable;