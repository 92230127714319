import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { Tab } from "src/components/Tabs/Pills";
import AgendaToday from "./AgendaSections/Today";
import AgendaWeek from "./AgendaSections/Week";

const AgendaView = () => {
  const { t } = useTranslation();

  const tabs: Tab[] = [
    { id: 'today', name: t('booking.events.filters.today'), visible: false },
    { id: 'week', name: t('booking.events.filters.thisWeek') },
  ]
  const [currentTab, setCurrentTab] = useState('week');

  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl">{t('panel.settings.agenda')}</h1>
      </div>
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <div className="my-6 py-6 border-y border-gray-100 text-sm leading-6">
        {currentTab == 'today' && <AgendaToday />}
        {currentTab == 'week' && <AgendaWeek />}
      </div>
    </>
  )
}

export default AgendaView;