import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LocalizedLink from "src/components/Router/LocalizedLink";

export default function Error404() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>{t('common.errors.404')}</title>
      </Helmet>
      <div className="flex flex-1 min-h-screen bg-gradient-to-t from-[--color-primary-50] to-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:max-w-xl">

            <div className="text-center">
              <div className="text-base font-medium text-[--color-primary-600]">404</div>
              <h1 className="mt-4 text-3xl font-medium tracking-tight text-gray-900 sm:text-5xl">{t('common.errors.404')}</h1>
              <div className="mt-6 text-base leading-7 text-gray-600">{t('common.errors.descriptions.404')}</div>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <LocalizedLink
                  to="/"
                  className="rounded-md bg-[--color-primary-600] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {t('common.errors.actions.goBackHome')}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/backgrounds/desert.webp"
            alt=""
          />
        </div>
      </div>
    </>
  )
}