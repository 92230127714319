import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'src/components/Utilities/classNames'
import { Outlet, useLocation } from 'react-router'
import Header from '../Front/Header'
import { User } from 'src/api/access/Auth'
import useUser from 'src/hooks/useUser'
import { useTranslation } from 'react-i18next'
import LocalizedLink from 'src/components/Router/LocalizedLink'
import Footer from '../Front/Footer'
import { useEffect, useState } from 'react'
import useLocalizedNavigate from 'src/hooks/useNavigate'
import { faBell, faEnvelope, faFingerprint, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import usePageTracking from 'src/hooks/usePageTracking'
import { Helmet } from 'react-helmet'
import { faHorseSaddle } from '@fortawesome/pro-duotone-svg-icons'
import useAuthGuard from 'src/hooks/useAuthGuard'
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading'

export default function UserPanel() {
  const location = useLocation();
  usePageTracking();
  const navigate = useLocalizedNavigate();
  const user = useUser();
  const { t } = useTranslation();
  const pathname = location.pathname.substring(3);
  const [currentTab, setCurrentTab] = useState('');
  const authGuard = useAuthGuard('/auth/sign-in/');

  const secondaryNavigation = [
    { name: 'users.panel.general', href: '/user/', icon: faUser, check: (_?: User) => true },
    { name: 'stable.trainings.group', href: '/user/trainings/', icon: faHorseSaddle, check: (_?: User) => true },
    { name: 'users.form.password.header', href: '/user/change-password/', icon: faFingerprint, check: (_?: User) => true },
    { name: 'auth.changeEmail.header', href: '/user/change-email/', icon: faEnvelope, check: (_?: User) => true },
    { name: 'auth.confirmEmail.action', href: '/user/confirm/', icon: faEnvelope, check: (user?: User) => !user?.emailConfirmed },
    { name: user?.phoneNumber ? 'auth.changePhoneNumber.header' : 'auth.addPhoneNumber.header', href: '/user/change-phone-number/', icon: faPhone, check: (_?: User) => true },
    { name: 'auth.notificationPreferences.header', href: '/user/notification-preferences/', icon: faBell, check: (_?: User) => true },
  ]

  useEffect(() => {
    if (currentTab) {
      window.scrollTo(0, 0);
      navigate(currentTab);
    }
  }, [currentTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (authGuard === undefined) return <AuthGuardLoading />;

  return (
    <>
      <Helmet>
        <title>{t('users.panel.header')}</title>
      </Helmet>
      <Header />
      <div className="mx-auto max-w-7xl px-4 lg:pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <div className="md:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-[--color-primary-500] focus:ring-[--color-primary-500]"
            defaultValue={currentTab}
            value={currentTab}
            onChange={(e) => setCurrentTab(e.target.value)}
          >
            {secondaryNavigation.filter(n => n.check(user)).map((item) => (
              <option key={item.href} value={item.href}>{t(item.name)}</option>
            ))}
          </select>
        </div>
        <aside className="overflow-x-auto border-b border-gray-900/5 py-4 hidden md:block md:w-64 flex-none border-0 md:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap flex-col">
              {secondaryNavigation.filter(n => n.check(user)).map((item) => (
                <li key={item.name}>
                  <LocalizedLink
                    to={item.href}
                    className={classNames(
                      item.href === pathname
                        ? 'bg-gray-50 text-[--color-primary-600]'
                        : 'text-gray-700 hover:text-[--color-primary-600] hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-medium'
                    )}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className={classNames(
                        item.href === pathname ? 'text-[--color-primary-600]' : 'text-gray-400 group-hover:text-[--color-primary-600]',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {t(item.name)}
                  </LocalizedLink>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="md:px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <Outlet />
        </main>
      </div>
      <Footer />
    </>
  )
}
