import { FC, useEffect, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Rider, RidersClient } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import Tabs, { Tab } from 'src/components/Tabs/Pills';
import Trainings from './Trainings';
import TrainingTypesForm from './Form/TrainingTypes';
import HorsesForm from './Form/Horses';
import InstructorsForm from './Form/Instructors';
import Calendar from './Calendar';
import { User, UsersClient } from 'src/api/access/Authority';

const RidersForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new RidersClient(apiConfiguration);
  const userApiClient = new UsersClient(apiConfiguration);
  const form = useForm<Rider>();
  const addUserForm = useForm<User>();
  const authGuard = useAuthGuard('/panel/', ['RidersRead']);
  const RidersUpdateClaim = useClaim('RidersUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const tabs: Tab[] = [
    { id: 'data', name: 'common.data' },
    { id: 'assignments', name: 'stable.booking.assignments' },
    { id: 'trainings', name: 'stable.trainings.group', visible: false },
    { id: 'calendar', name: 'ui.calendar.calendar', visible: false },
  ];
  const [currentTab, setCurrentTab] = useState<string>("");

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/riders/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      if (addUserForm.data.email && addUserForm.data.givenName && addUserForm.data.surname) {
        const addUserData = { ...addUserForm.data, userName: addUserForm.data.email } as User;
        if (addUserData.password === '') {
          delete addUserData.password;
        }
        userApiClient.create(addUserData)
          .then((userResponse) => {
            apiClient.create({ ...form.data, userId: userResponse.id } as Rider)
              .then(() => {
                Toast.success(t("common.status.success"), t("common.form.saved"));
                navigate(`/panel/stable/riders/`);
              })
              .catch(e => form.catchAnyException(e, true))
              .finally(() => form.setPending(false));
          })
          .catch(e => form.catchAnyException(e, true))
          .finally(() => form.setPending(false));
      } else {
        apiClient.create(form.data)
          .then(() => {
            Toast.success(t("common.status.success"), t("common.form.saved"));
            navigate(`/panel/stable/riders/`);
          })
          .catch(e => form.catchAnyException(e, true))
          .finally(() => form.setPending(false));
      }

    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.riders.group", href: "/panel/stable/riders/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/stable/riders/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!RidersUpdateClaim);
  }, [RidersUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header Rider={form.data} onDelete={onDelete} form={form} />
        <Tabs tabs={tabs.map(t => ({ ...t, visible: form.data.id ? true : t.visible } as Tab))} currentTab={currentTab} setCurrentTab={setCurrentTab} className="mt-3" />
        {currentTab == 'data' && <>
          <GeneralForm form={form} addUserForm={addUserForm} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </>}
        {currentTab == 'assignments' && <>
          <HorsesForm form={form} />
          <InstructorsForm form={form} />
          <TrainingTypesForm form={form} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </>}
        {currentTab == 'trainings' && <>
          <Trainings form={form} />
        </>}
        {currentTab == 'calendar' && <>
          <Calendar form={form} />
        </>}
      </form >
    </>
  )
}

export default RidersForm;