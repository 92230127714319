
import { ChangeEvent, useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useForm, { IForm } from 'src/hooks/useForm';
import FormInput from 'src/components/Form/FormInput';
import useUser from 'src/hooks/useUser';
import { Dictionary } from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { PaymentMethodsClient, PaymentMethod, PaymentMethodTranslation } from 'src/api/financial/Payments';
import Spinner from 'src/components/Feedback/Spinner';
import { PersonalData } from 'src/api/stable/Booking';
import ContentParse from 'src/components/Cms/ContentParse';
import { PhoneNumberFormInset } from 'src/components/Form/PhoneNumberForm';
import { isFunctionalityEnabled } from 'src/config/config';
import BookTrainingHeader from './BookTrainingHeader';

interface PersonalDataViewComponentProps {
  onSelect: (pd: PersonalData, pm?: PaymentMethod) => void;
  onGoBack: () => void;
  personalData?: PersonalData;
}

interface PaymentMethodFormComponentProps {
  paymentMethod?: PaymentMethod;
  setPaymentMethod: (v?: PaymentMethod) => void;
}

interface PersonalDataFormComponentProps {
  form: IForm<PersonalData>;
  disabled: boolean;
}

const PaymentMethodForm = (props: PaymentMethodFormComponentProps) => {
  const { paymentMethod, setPaymentMethod } = props;
  const [methods, setMethods] = useState<PaymentMethod[]>();
  const [loading, setLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(methods?.find(p => p.id === e.target.value));
  }

  useEffect(() => {
    setLoading(true);
    paymentMethodsClient
      .get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then(response => setMethods(response.items ?? []))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const options = methods?.map(m => ({
    id: m.id,
    value: m.id,
    label: entityTranslation.getCurrentTranslation(m)?.title,
    description: <ContentParse>{entityTranslation.getCurrentTranslation(m)?.description}</ContentParse>
  }) as GridSelectOption);
  return (
    <>
      {loading ?? <Spinner className="mx-auto h-8" />}
      {options && <GridSelect value={paymentMethod?.id} options={options} onChange={onChange} full />}
    </>
  )
}

const PersonalDataForm = (props: PersonalDataFormComponentProps) => {
  const { form, disabled } = props;
  const { t } = useTranslation();
  return (<>
    <div className="py-3">
      <FormInput.Inset {...form.input('givenName', 'text', { placeholder: t('users.fields.givenName'), required: true, minLength: 2, disabled: disabled })} />
    </div>
    <div className="py-3">
      <FormInput.Inset {...form.input('surname', 'text', { placeholder: t('users.fields.surname'), required: true, minLength: 2, disabled: disabled })} />
    </div>
    <div className="py-3">
      <FormInput.Inset {...form.input('email', 'email', { placeholder: t('auth.fields.email'), required: true, minLength: 4, disabled: disabled })} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title={t('validation.NotValidEmail')} />
    </div>
    <div className="py-3">
      <PhoneNumberFormInset {...form.input('phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), disabled: disabled })} />
    </div>
  </>)
}

const PersonalDataView = (props: PersonalDataViewComponentProps) => {
  const { onSelect, onGoBack, personalData } = props;
  const user = useUser();
  const { t } = useTranslation();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const form = useForm<PersonalData>({ ...{}, ...user, ...personalData } as PersonalData);

  const arePaymentsAvailable = isFunctionalityEnabled('payments');

  const validate = () => {
    const result: Dictionary<string[]> = {};
    if (arePaymentsAvailable && paymentMethod === undefined) {
      result.paymentMethod = ['Required'];
    }
    return result;
  }

  const onSubmit = () => {
    onSelect(form.data, paymentMethod);
    form.setPending(false);
  }

  const isDisabled: boolean = (arePaymentsAvailable && !paymentMethod) || !form.data.givenName || !form.data.surname || !form.data.email || form.pending;

  return (
    <form onSubmit={(e) => form.onSubmit(e, onSubmit, validate)}>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <BookTrainingHeader onClickBack={onGoBack} disabledNext={isDisabled} submitButton />
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-2xl pb-5 mb-5 border-b border-gray-200">{t('stable.trainings.booking.fillPersonalData')}</h2>
            <PersonalDataForm form={form} disabled={user !== undefined} />
          </div>
          {arePaymentsAvailable &&

            <div className="lg:w-7/12">
              <h2 className="text-2xl pb-5 mb-5 border-b border-gray-200">{t('stable.trainings.booking.selectPaymentMethod')}</h2>
              <PaymentMethodForm paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
            </div>
          }
        </div>
        <BookTrainingHeader onClickBack={onGoBack} disabledNext={isDisabled} submitButton />
      </div>
    </form>
  )
}

export default PersonalDataView;