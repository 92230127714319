import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { ClientNewsfeedCategoriesClient, ClientNewsfeedPostsClient, Content, ContentTranslation, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import Breadcrumbs from "src/components/Navigation/Breadcrumbs";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setBreadcrumbs } from "src/store/application/actions";
import useLocalizedNavigate from "src/hooks/useNavigate";
import NewsletterBar from "src/components/Front/NewsletterBar";
import ContentParse from "src/components/Cms/ContentParse";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import SectionHeader from "src/components/Typography/SectionHeader";

export interface PostElementComponentProps {
  post: Content;
}

export const PostHeader = (props: PostElementComponentProps) => {
  const { post } = props;
  const { i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(post);
  return (
    <>
      <Helmet>
        <title>{translation?.meta?.title}</title>
        <meta name="title" content={translation?.meta?.title} />
        <meta name="description" content={translation?.meta?.description} />
        <meta name="keywords" content={translation?.meta?.keywords?.toLocaleLowerCase()} />
      </Helmet>
      <div>
        <Breadcrumbs />
      </div>
      <div className="flex border-b border-gray-200 pb-5 mt-12 mb-5 justify-between">
        <SectionHeader>{translation?.title}</SectionHeader>
        <time dateTime={post.created?.toLocaleString()} className="text-white mt-3 bg-gray-400 rounded-md px-3 py-1 text-sm">
          {post.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}
        </time>
      </div>
    </>
  )
}

export const PostContent = (props: PostElementComponentProps) => {
  const { post } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(post);
  return (
    <div className="border-b border-gray-200 pb-5 mb-5">
      <ContentParse>{translation?.content}</ContentParse>
    </div>
  )
}

export const PostFooter = (props: PostElementComponentProps) => {
  const { post } = props;
  const { t, i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(post);
  return (
    <div className="border-b border-gray-200 pb-5 flex justify-between text-xs text-gray-400">
      <div>
        <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-3" /> {translation?.meta?.keywords}
        &nbsp;
      </div>
      <div>
        {post.updated && <span>{t('common.fields.updated')}: {post.updated?.toLocaleString(i18n.resolvedLanguage)}</span>}
      </div>
    </div>
  )
}

const NewsfeedPost = () => {
  const { url } = useParams<string>();
  const { i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientNewsfeedPostsClient(apiConfiguration);
  const categoriesClient = new ClientNewsfeedCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [post, setPost] = useState<Content | undefined>();
  const [category, setCategory] = useState<Content | undefined>();
  const translation = entityTranslation.getCurrentTranslation(post);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    apiClient.findByUrl(url || "")
      .then(response => setPost(response))
      .catch(onError);
  }, [url, i18n?.resolvedLanguage]);

  useEffect(() => {
    if (post?.parentId) {
      categoriesClient.findById(post?.parentId)
        .then(response => setCategory(response))
        .catch(onError);
    }
  }, [post]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.newsfeed.posts.group', href: `/n` }];
    if (category) {
      const categoryTranslation = entityTranslation.getCurrentTranslation(category);
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/n/c/${categoryTranslation?.url ?? category?.id}` });
    }
    if (post) {
      breadcrumbs.push({ label: translation?.meta?.title || translation?.title || "Untitled", href: `/n/p/${url}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [post, category]);

  if (!post) {
    return <Spinner className="h-24" />;
  }

  return (
    <>

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto">
        <PostHeader post={post} />
        <PostContent post={post} />
        <PostFooter post={post} />
      </div>
      <NewsletterBar />
    </>
  )
}

export default NewsfeedPost;