import React, { FC, useEffect, useReducer } from 'react';
import { ConfigurationState, initialConfigurationState } from './state';
import { ConfigurationActions, setConfiguration } from './actions';
import { configurationReducer } from './reducer';
import _ from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { SettingsClient } from 'src/api/core/Core';

export const ConfigurationContext = React.createContext<{
  state: ConfigurationState;
  dispatch: React.Dispatch<ConfigurationActions>;
}>({
  state: initialConfigurationState,
  dispatch: () => undefined,
});

interface ConfigurationProviderProps {
  children: React.ReactNode;
}

export const ConfigurationProvider: FC<ConfigurationProviderProps> = ({ children }) => {
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);
  const [state, dispatch] = useReducer(
    configurationReducer,
    {
      ...initialConfigurationState,
    }
  );

  useEffect(() => {
    apiClient
      .get('layout', undefined)
      .catch(console.error)
      .then(response => {
        if (response && response.value)
          dispatch(setConfiguration('layout', response.value))
      });
    apiClient
      .get('booking', undefined)
      .catch(console.error)
      .then(response => {
        if (response && response.value)
          dispatch(setConfiguration('booking', response.value))
      });
    apiClient
      .get('social', undefined)
      .catch(console.error)
      .then(response => {
        if (response && response.value)
          dispatch(setConfiguration('social', response.value))
      });
  }, []);

  useEffect(() => {
    const colors = [
      { id: "--font-default", value: state.layout.defaultFont },
      { id: "--color-primary-50", value: `var(--color-${state.layout.primaryColor}-50)` },
      { id: "--color-primary-100", value: `var(--color-${state.layout.primaryColor}-100)` },
      { id: "--color-primary-200", value: `var(--color-${state.layout.primaryColor}-200)` },
      { id: "--color-primary-300", value: `var(--color-${state.layout.primaryColor}-300)` },
      { id: "--color-primary-400", value: `var(--color-${state.layout.primaryColor}-400)` },
      { id: "--color-primary-500", value: `var(--color-${state.layout.primaryColor}-500)` },
      { id: "--color-primary-600", value: `var(--color-${state.layout.primaryColor}-600)` },
      { id: "--color-primary-700", value: `var(--color-${state.layout.primaryColor}-700)` },
      { id: "--color-primary-800", value: `var(--color-${state.layout.primaryColor}-800)` },
      { id: "--color-primary-900", value: `var(--color-${state.layout.primaryColor}-900)` },
    ];

    for (const color of colors) {
      document.body.style.setProperty(color.id, color.value);
    }
  }, [state.layout.primaryColor, state.layout.defaultFont]);



  return (
    <ConfigurationContext.Provider value={{ state, dispatch }}>
      {children}
    </ConfigurationContext.Provider>
  );
};