import { faHorseSaddle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { Place, PlaceTranslation } from "src/api/stable/Stable";
import Button from "src/components/Actions/Button";
import useClaim from "src/hooks/useClaim";

import useEntityTranslation from "src/hooks/useEntityTranslation";
import { IForm } from "src/hooks/useForm";

export interface PlaceHeaderComponentProps {
  Place: Place;
  onDelete: () => void;
  form: IForm<Place>
}

export default (props: PlaceHeaderComponentProps) => {
  const { Place, onDelete, form } = props;
  const { t } = useTranslation();

  const entityTranslation = useEntityTranslation<Place, PlaceTranslation>();
  const updateClaim = useClaim('PlacesUpdate');
  const deleteClaim = useClaim('PlacesDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <FontAwesomeIcon icon={faHorseSaddle} className="h-16 p-3 text-[--color-primary-500]" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{entityTranslation.getCurrentTranslation(Place)?.name || t('stable.places.actions.create')}</h1>
          <div className="text-sm font-medium text-gray-500">
            {Place.created && <span>{t('common.fields.created')}: {Place.created?.toLocaleString()}</span>}
            {Place.updated && <span>, {t('common.fields.updated')}: {Place.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {updateClaim && <Button colorName="primary" disabled={form.pending}>{t('common.actions.save')}</Button>}
        {deleteClaim && <Button type="button" colorName="red" onClick={onDelete} disabled={form.pending}>{t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
