import useForm from "src/hooks/useForm";
import FormInput from "../../components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { AuthClient, PhoneNumberChangeConfirmRequest, PhoneNumberChangeRequest } from "src/api/access/Auth";
import FormAlert from "src/components/Form/FormAlert";
import Alert from "src/components/Feedback/Alert";
import useUser from "src/hooks/useUser";
import BulletSteps from "src/components/Form/Steps/BulletSteps";
import { useParams } from "react-router-dom";
import PhoneNumberForm from "src/components/Form/PhoneNumberForm";

enum ChangePhoneNumberStep {
  Prompt,
  Confirm,
  Done
}

export interface ChangePhoneNumberPromptFormComponentProps {
  onPrompt: (e: string) => void;
}
export interface ChangePhoneNumberConfirmFormComponentProps {
  onConfirm: () => void;
  phoneNumber: string;
  token?: string;
}

const ChangePhoneNumberPromptForm = (props: ChangePhoneNumberPromptFormComponentProps) => {
  const { onPrompt } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const user = useUser();

  const form = useForm({} as PhoneNumberChangeRequest);
  const onSubmit = () => {
    authClient
      .phoneNumberChange(form.data)
      .then(_ => onPrompt(form.data.newPhoneNumber))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <div className=" text-gray-600 text-sm">{t(user?.phoneNumber ? 'auth.changePhoneNumber.description' : 'auth.addPhoneNumber.description')}</div>
      <div className="lg:w-1/2 mt-10">
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...t("auth.errors", { returnObjects: true })
          }}
        />

        <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
          {user?.phoneNumber && <FormInput.Overlapping value={user?.phoneNumber} placeholder={t('auth.fields.phoneNumber')} disabled />}
          <PhoneNumberForm {...form.input('newPhoneNumber', 'text', { placeholder: t('auth.fields.newPhoneNumber'), required: true })} />
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[--color-primary-600] px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('auth.changePhoneNumber.sendToken')}
            </button>
          </div>
        </form>
      </div >
    </>
  );
}

const ChangePhoneNumberConfirmForm = (props: ChangePhoneNumberConfirmFormComponentProps) => {
  const { phoneNumber, token, onConfirm } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const user = useUser();

  const form = useForm<PhoneNumberChangeConfirmRequest>({ newPhoneNumber: phoneNumber, token: token } as PhoneNumberChangeConfirmRequest);
  const onSubmit = () => {
    authClient
      .phoneNumberChangeConfirm(form.data)
      .then(_ => onConfirm())
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <div className=" text-gray-600 text-sm">{t('auth.changePhoneNumber.sentToken')}</div>
      <div className="lg:w-1/2 mt-10">
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...t("auth.errors", { returnObjects: true })
          }}
        />

        <form onSubmit={e => form.onSubmit(e, onSubmit)} className="space-y-6">
          {user?.phoneNumber && <FormInput.Overlapping value={user?.phoneNumber} placeholder={t('auth.fields.phoneNumber')} disabled />}
          <FormInput.Overlapping {...form.input('newPhoneNumber', 'text', { placeholder: t('auth.fields.newPhoneNumber'), autoComplete: 'tel', required: true, readOnly: true })} />
          <FormInput.Overlapping {...form.input('token', 'text', { placeholder: t('auth.token'), required: true })} />
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[--color-primary-600] px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t(user?.phoneNumber ? 'auth.changePhoneNumber.action' : 'auth.addPhoneNumber.action')}
            </button>
          </div>
        </form>
      </div >
    </>
  );
}

const ChangePhoneNumberDoneForm = () => {
  const { t } = useTranslation();
  const user = useUser();
  return (
    <>
      <div>
        <Alert.Success title={t(user?.phoneNumber ? 'auth.changePhoneNumber.phoneNumberChanged' : 'auth.addPhoneNumber.phoneNumberChanged')} noClose />
      </div>
    </>
  );
}


export default () => {
  const { t } = useTranslation();
  const user = useUser();
  const { phoneNumber, token } = useParams<string>();

  const [step, setStep] = useState(ChangePhoneNumberStep.Prompt);
  const [formPhoneNumber, setFormPhoneNumber] = useState('');

  useEffect(() => {
    if (phoneNumber || token) {
      setStep(ChangePhoneNumberStep.Confirm);
    }
  }, [phoneNumber, token]);

  const onPrompt = (e: string) => {
    setStep(ChangePhoneNumberStep.Confirm);
    setFormPhoneNumber(e);
  }

  const onConfirm = () => {
    setStep(ChangePhoneNumberStep.Done);
  }

  const steps = [
    { name: 'Request' },
    { name: 'Confirmation', disabled: step < 1 },
    { name: 'Done', disabled: step < 2 }
  ];

  return (
    <>
      <div>
        <div>
          <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
            {t(user?.phoneNumber ? 'auth.changePhoneNumber.header' : 'auth.addPhoneNumber.header')}
          </h2>
        </div >
        <div className="mt-5 mb-10">
          {step === ChangePhoneNumberStep.Prompt && <ChangePhoneNumberPromptForm onPrompt={onPrompt} />}
          {step === ChangePhoneNumberStep.Confirm && <ChangePhoneNumberConfirmForm phoneNumber={phoneNumber || formPhoneNumber} token={token} onConfirm={onConfirm} />}
          {step === ChangePhoneNumberStep.Done && <ChangePhoneNumberDoneForm />}
        </div>
        <div className="lg:w-1/2">
          <BulletSteps steps={steps} currentStep={step} onClick={(step, i) => setStep(i)} />
        </div>
      </div>
    </>
  );
}