import { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientGalleriesClient, ClientGalleryPhotosClient, Content } from 'src/api/cms/Cms';
import Button from 'src/components/Actions/Button';
import Spinner from 'src/components/Feedback/Spinner';
import FileUploadDialog from 'src/components/Form/File/FileUploadDialog';
import FormSection from 'src/components/Layout/Panel/FormSection';
import configuration, { ConfigurationApis } from 'src/config/config';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { IForm } from 'src/hooks/useForm';
import useTenant from 'src/hooks/useTenant';

export interface PhotosFormComponentProps {
  form: IForm<Content>;
}

export interface PhotoComponentProps extends HTMLAttributes<HTMLElement> {
  photo: Content;
}

function Photo(props: PhotoComponentProps) {
  const { photo, ...otherProps } = props;
  const tenant = useTenant();
  const url = `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${photo.id}/download?XTenantId=${tenant}`;
  return (
    <div className="w-full md:w-1/2 xl:w-1/4 p-3" {...otherProps}>
      <img src={url} alt={photo.name} title={photo.name} />
    </div>
  )
}

export default function PhotosForm(props: PhotosFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const galleriesClient = new ClientGalleriesClient(apiConfiguration);
  const photosClient = new ClientGalleryPhotosClient(apiConfiguration);
  const [loading, setLoading] = useState(false);
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [photos, setPhotos] = useState<Content[]>([]);

  useEffect(() => {
    if (!form.data.id) return;
    setLoading(true);
    galleriesClient
      .getPhotos(
        form.data.id!,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setPhotos(response.items || []))
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [form.data.id]);

  const onUploadPhoto = (response: Content) => {
    setPhotos([...photos, response]);
    setUploadDialogOpen(false);
  }

  const onSelectFile = (file: File) => {
    photosClient.uploadPhoto(form.data.id!, undefined, { fileName: file.name, data: file })
      .then(onUploadPhoto)
      .catch(_ => setUploadError(true));
  }

  const deleteFile = (id: string) => {
    photosClient.deletePhoto(id)
      .then(() => setPhotos([...photos.filter(p => p.id !== id)]))
      .catch(console.error);
  }


  return (
    <FormSection
      title={t('cms.galleries.galleries.form.photos.header')}
      description={t('cms.galleries.galleries.form.photos.description')}
      full
    >
      {loading && <Spinner className="h-8 mx-auto" />}
      <FileUploadDialog open={isUploadDialogOpen} setOpen={setUploadDialogOpen} onSelectFile={onSelectFile} uploadError={uploadError} />
      <div className="flex flex-wrap">
        {photos.map(photo => <Photo key={photo.id} photo={photo} onClick={() => deleteFile(photo.id!)} />)}
      </div>
      <Button colorName="paradise" className="px-5 py-3" type="button" onClick={() => setUploadDialogOpen(true)}>
        Upload photo
      </Button>
    </FormSection>
  )
}