import usePageTracking from 'src/hooks/usePageTracking';
import Footer from './Footer'
import Header from './Header'
import { Outlet } from 'react-router'

const FrontLayout = () => {
  usePageTracking();
  return (
    <>
      <div className="bg-gradient-to-t from-[--color-primary-50] to-white">
        <Header />
        <div className="pt-4">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default FrontLayout;