import { useTranslation } from "react-i18next";
import PageLink from "../Cms/PageLink";

const NewsletterBar = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gradient-to-b from-[--color-primary-800] to-[--color-primary-900] py-16 sm:py-24 lg:py-32 hidden">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-medium tracking-tight text-white sm:text-4xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block text-[--color-primary-200]">{t('crm.newsletter.header')}</h2>{' '}
          <div className="inline sm:block lg:inline xl:block">{t('crm.newsletter.subheader')}</div>
        </div>
        <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              {t('users.fields.email')}
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-white/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              placeholder={t('crm.newsletter.enterYourEmail')}
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-medium text-[--color-primary-600] shadow-sm hover:bg-[--color-primary-50] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              {t('crm.newsletter.actions.subscribe')}
            </button>
          </div>
          <div className="mt-4 text-sm leading-6 text-gray-300">
            <span className="mr-1">{t('privacy.slogan')}</span>
            <PageLink id={"b7cdaccb-08cc-49f8-82ad-739e1f738378"} className="font-medium text-white hover:text-[--color-primary-50]" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewsletterBar;