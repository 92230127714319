import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { User, UsersClient } from 'src/api/access/Authority';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import useUser from 'src/hooks/useUser';
import Toast from 'src/components/Feedback/Toast';
import { useSignalRHub } from "use-signalr-hub";
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'src/components/User/Avatar';
import { CountryFlag, getCountryCodeByLocaleCode } from 'src/components/Layout/Panel/LocaleMenu';
import { ConfigurationApis, getApiBaseUrl } from 'src/config/config';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

interface UserFilters {
  email?: string;
  userName?: string;
  givenName?: string;
  surname?: string;
  phoneNumber?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<UserFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.email) f.push({ property: 'email', value: form.data.email, type: '%' });
  if (form.data.userName) f.push({ property: 'userName', value: form.data.userName, type: '%' });
  if (form.data.givenName) f.push({ property: 'givenName', value: form.data.givenName, type: '%' });
  if (form.data.surname) f.push({ property: 'surname', value: form.data.surname, type: '%' });
  if (form.data.phoneNumber) f.push({ property: 'phoneNumber', value: form.data.phoneNumber, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<UserFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("email", "text", { placeholder: t('users.fields.email') })} />
        <FormInput.Default {...form.input("userName", "text", { placeholder: t('users.fields.userName') })} />
        <FormInput.Default {...form.input("givenName", "text", { placeholder: t('users.fields.givenName') })} />
        <FormInput.Default {...form.input("surname", "text", { placeholder: t('users.fields.surname') })} />
        <FormInput.Default {...form.input("phoneNumber", "text", { placeholder: t('auth.fields.phoneNumber') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const UsersTable = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new UsersClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<User>>();
  const user = useUser();
  const usersUpdateClaim = useClaim('UsersUpdate');
  const usersDeleteClaim = useClaim('UsersDelete');
  const authGuard = useAuthGuard('/panel/', ['UsersRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastPage() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Authority)}/hubs/UsersHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);

  const onClickDelete = (user: User) => {
    if (user?.id) {
      apiClient.delete(user.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<User>[] = [
    { label: '', selector: row => <Avatar userId={row.id} className="h-8 w-8 rounded-full ring-1" />, id: "translations.name" },
    { label: t('users.fields.culture'), selector: row => <CountryFlag code={getCountryCodeByLocaleCode(row.culture || "en")} className="inline h-3 mr-2 shadow-md" />, id: "culture" },
    { label: t('users.fields.userName'), selector: row => row.userName, isOrderable: true, id: "userName" },
    { label: t('users.fields.email'), selector: row => <><VerifiedBadge value={row.emailConfirmed} /> {row.email}</>, isOrderable: true, id: "email" },
    { label: t('auth.fields.phoneNumber'), selector: row => <><VerifiedBadge value={row.phoneNumberConfirmed} /> {row.phoneNumber}</>, isOrderable: true, id: "phoneNumber" },
    { label: t('users.fields.givenName'), selector: row => row.givenName, isOrderable: true, id: "givenName" },
    { label: t('users.fields.surname'), selector: row => row.surname, isOrderable: true, id: "surname" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {usersUpdateClaim &&
          <LocalizedLink to={`/panel/users/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {usersDeleteClaim && row.id !== user?.id &&
          <Button colorName="red" onClick={() => onClickDelete(row)}>
            <FontAwesomeIcon icon={faTimes} className="w-5" />
          </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "users.group", href: "/panel/users/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <h1 className="leading-1 text-2xl">{t('users.group')}</h1>
        </div>
        <div className="">
          {usersUpdateClaim &&
            <LocalizedLink to="/panel/users/create">
              <Button colorName="emerald" className="px-5 py-2">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('users.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<User>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
    </>
  )
}

export interface VerifiedBadgeProps {
  value?: boolean;
}
const VerifiedBadge = ({ value }: VerifiedBadgeProps) =>
  value ? <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-500 mr-1" /> : <></>;

export default UsersTable;