import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { HttpQueryFilter, TrainingType, TrainingTypeTranslation, TrainingTypesClient } from 'src/api/stable/Stable';
import ComboBox from 'src/components/Form/FormComboBox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useEntityTranslation from 'src/hooks/useEntityTranslation';

export interface TrainingTypeSelectComponentProps {
  onChange: ((id: string | Dictionary<TrainingType | undefined>, entity?: TrainingType) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
}

const TrainingTypeSelect = (props: TrainingTypeSelectComponentProps) => {
  const { onChange, value, multiple, ...otherProps } = props;
  const apiConfiguration = useApiConfiguration();
  const entityTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const client = new TrainingTypesClient(apiConfiguration);
  const [entities, setEntities] = useState<TrainingType[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  useEffect(() => {
    applyAutocomplete('');
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, entities.find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: entities.find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const applyAutocomplete = (query: string) => {
    client
      .get(
        query
          .split(' ')
          .filter(phrase => phrase.length > 0)
          .map(phrase => ({ type: '%', property: 'Translations.Name', value: phrase } as HttpQueryFilter)),
        [],
        10,
        0,
        undefined,
        undefined
      )
      .then(response => setEntities(response.items ?? []))
      .catch(console.error);
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => applyAutocomplete(query), 250);
  };
  const options = entities?.map(r => ({ value: r.id, label: entityTranslation.getCurrentTranslation(r)?.name }) as TextOptionProps);

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default TrainingTypeSelect;