import { useTranslation } from 'react-i18next';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { IForm } from 'src/hooks/useForm';
import { UserForm } from '../Form';
import { Role, RolesClient } from 'src/api/access/Authority';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { useEffect, useState } from 'react';

export interface RolesFormComponentProps {
  form: IForm<UserForm>;
}

export default function RolesForm(props: RolesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const rolesClient = new RolesClient(apiConfiguration);
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);

  useEffect(() => {
    if (roles === undefined)
      rolesClient
        .get([], [], 1000, 0, 0, 0)
        .then(r => setRoles(r.items))
        .catch(console.error);
  }, [roles])

  return (
    <div className="md:flex md:flex-row md:mt-5 pb-5 border-b border-gray-200">
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 sm:px-0">
        <h3 className="text-base font-medium leading-7 text-gray-900">{t('users.form.roles.header')}</h3>
        <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{t('users.form.roles.subheader')}</div>
      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 md:border-l border-gray-100">
        <FormCheckbox.Set title="Roles">
          {roles?.map(r => <FormCheckbox.Input key={r.id} {...form.input('roles[]', 'checkbox', { value: r.name, placeholder: r.name, "aria-description": r.id })} />)}
        </FormCheckbox.Set>
      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10">
      </div>
    </div>
  )
}