
interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
  color?: boolean;
}

const SectionDescription = (props: HeaderProps) => {
  const { children, className, color } = props;

  return (
    <h2 className={`mt-6 text-xl leading-8 ${color ? "text-[--color-primary-900]" : "text-gray-600"} ${className}`}>
      {children}
    </h2>
  )
}

export default SectionDescription;