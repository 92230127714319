import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarWeekView, { AvailableHours, CalendarEvent } from "src/components/Calendar/CalendarWeekView";
import moment from 'moment';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Horse, Instructor, TrainingType } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { AvailabilitiesRequest, RiderTrainingsClient, Training } from 'src/api/stable/Booking';
import _ from 'lodash';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import Alert from 'src/components/Feedback/Alert';
import CalendarWeekController from 'src/components/Calendar/CalendarWeekController';
import useConfigurationState from 'src/hooks/useConfigurationState';
import BookTrainingHeader from './BookTrainingHeader';


interface Event {
  id: string;
  start: Date;
  end: Date;
}

export interface CalendarComponentProps {
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
  onSelect: (value: Date) => void;
  onGoBack: () => void;
}

const Calendar = (props: CalendarComponentProps) => {
  const { type, instructor, horse, onSelect, onGoBack } = props;
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new RiderTrainingsClient(apiConfiguration);
  const configurationState = useConfigurationState();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [startCalendar, setStartCalendar] = useState(moment().startOf('isoWeek').toDate());
  const selectedEndDate = selectedDate ? moment(selectedDate).add(type.duration, 'minutes').toDate() : undefined;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);

  const getUnavailabilities = () => {
    setLoading(true);
    apiClient
      .unavailabilities({
        start: new Date(startCalendar),
        end: new Date(moment(startCalendar).add(7, 'days').subtract(1, 'second').toDate()),
        training: {
          typeId: type.id || undefined,
          instructorId: instructor?.id || undefined,
          horseId: horse?.id || undefined
        } as Training
      } as AvailabilitiesRequest)
      .then(response => {
        setEvents(
          _.values(response).map(day => {
            return day.map((item) => ({
              id: String(item.id),
              start: item.start!,
              end: item.end!
            })) || []
          }).flat()
        );
        setLoading(false);
      })
      .catch(_ => setError(true));
  }

  useEffect(() => {
    getUnavailabilities();
  }, [startCalendar]);

  const ownEvents = [
    { id: "own", start: new Date(2024, 2, 21, 13, 0, 0), end: new Date(2024, 2, 21, 14, 0, 0) }
  ]
  const selectedEvents = selectedDate ? [{ id: "select", start: selectedDate, end: selectedEndDate!, label: t('ui.calendar.currentChoice'), colorName: "emerald", active: true }] : [];

  const calendarEvents: CalendarEvent[] = [
    ...events.map(event => ({ ...event, label: t('ui.calendar.notAvailable'), colorName: "red", disabled: true, visible: false })),
    ...ownEvents.map(event => ({ ...event, label: t('stable.trainings.myTraining'), colorName: "red", disabled: true })),
    ...selectedEvents
  ];

  const hours: AvailableHours[] = _.map(
    configurationState?.booking?.hours,
    (v, k) => ({ key: Number(k), start: [Number(v.start[0]), Number(v.start[1])], end: [Number(v.end[0]), Number(v.end[1])] })
  )
    .sort((a, b) => a.key - b.key)
    .map(v => ({ start: v.start, end: v.end }));

  const areDateRangesConflicted = (firstStart: Date, firstEnd: Date, secondStart: Date, secondEnd: Date) => {
    secondEnd.setSeconds(secondEnd.getSeconds() - 1);
    return (firstStart <= secondEnd && secondStart <= firstEnd) ||
      (secondStart <= firstEnd && firstStart <= secondEnd);
  }

  const onSubmit = () => {
    if (selectedDate) {
      onSelect(selectedDate);
    }
  }

  const isDateRangeAvailable = (start: Date, end: Date) => {
    end.setSeconds(end.getSeconds() - 1);
    const eventsInConflict = calendarEvents.filter(f => f.id !== 'select').some(e => areDateRangesConflicted(start, end, e.start, e.end));
    const weekDay = start.getDay();
    const todayHours = hours[weekDay];
    if (!todayHours) {
      return false;
    }
    const todayHoursCorrected = {
      start: todayHours.start[0],
      end: todayHours.end[1] != 0 ? (todayHours.end[0] + 1) : todayHours.end[0]
    }
    console.log(hours, weekDay, todayHours, todayHoursCorrected);
    const isInHoursDown = start.getHours() >= todayHoursCorrected.start;
    const isInHoursUp = end.getHours() <= todayHoursCorrected.end;
    return !eventsInConflict && isInHoursDown && isInHoursUp;
  }

  useEffect(() => {
    if (!selectedDate) return;
    if (!isDateRangeAvailable(selectedDate, selectedEndDate!)) {
      let canCorrect = false;
      for (let i = 1; i <= 3; i++) {
        const newDate = moment(selectedDate).subtract(15 * i, 'minutes').toDate();
        const newEndDate = moment(newDate).add(type.duration, 'minutes').toDate();
        if (isDateRangeAvailable(newDate, newEndDate)) {
          setSelectedDate(newDate);
          canCorrect = true;
          break;
        }
      }
      if (!canCorrect) {
        setSelectedDate(undefined);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    getUnavailabilities();
  }, [startCalendar]);

  return (
    <>
      <div className="max-w-sm md:max-w-full px-4 md:px-8 mx-auto mb-8">
        <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={!selectedDate || loading} >
          {t('stable.trainings.booking.selectTrainingDate')}
        </BookTrainingHeader>
        <div className="border-y border-y-gray-200 py-5 my-5">
          {error && <Alert.Error title={t('common.status.error')} noClose >{t('stable.trainings.errors.cantGetUnavailabilities')}</Alert.Error>}
          {!error && <div className="flex items-center lg:items-end">
            <div className="mx-auto justify-center lg:mr-0">
              <div className="relative items-center rounded-md bg-white shadow-sm md:items-stretch hidden md:flex">
                <CalendarWeekController currentDate={startCalendar} onChangeDate={setStartCalendar} />
              </div>
              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch mr-8 md:hidden">
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                >
                  <span className="sr-only">{t('ui.calendar.previousWeek')}</span>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center  border-y  border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                >
                  <span className="sr-only">{t('ui.calendar.previousDay')}</span>
                  <FontAwesomeIcon icon={faAngleLeft} className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="border border-gray-300 px-3.5 py-2.5 text-xs font-medium text-gray-900 hover:bg-gray-50 focus:relative md:block"
                >
                  {t('ui.calendar.today')}
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center border-y  border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                >
                  <span className="sr-only">{t('ui.calendar.nextDay')}</span>
                  <FontAwesomeIcon icon={faAngleRight} className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                >
                  <span className="sr-only">{t('ui.calendar.nextWeek')}</span>
                  <FontAwesomeIcon icon={faAngleDoubleRight} className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>}
          <div className="my-5">
            <div className="relative">
              {loading && <LoadingOverlay />}
              <CalendarWeekView
                startCalendar={startCalendar}
                startHour={_.min(hours.map(h => h.start[0])) || 7}
                endHour={_.max(hours.map(h => h.end[1] ? h.end[0] + 1 : h.end[0])) || 18}
                events={calendarEvents}
                onClickEvent={(event) => event.id == 'select' ? setSelectedDate(undefined) : setSelectedDate(selectedDate)}
                onClickTerm={(start, _) => setSelectedDate(start)}
                availableHours={hours}
                termInterval={type?.duration}
              />
            </div>
          </div>
        </div>
        <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={!selectedDate || loading} />
      </div>
    </>
  )
}

export default Calendar;