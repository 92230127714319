import { useEffect, useState } from "react";
import classNames from "../Utilities/classNames";
import Pagination from "./Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Alert from "../Feedback/Alert";

export interface IEntity {
  id?: string;
}

export enum TableOrderDirection {
  ASC = "asc",
  DESC = "desc"
}

export interface TableOrder {
  property: string;
  direction: TableOrderDirection;
}

export interface ITableColumn<T extends IEntity> {
  id: string;
  label: string;
  isOrderable?: boolean;
  isSearchable?: boolean;
  isBold?: boolean;
  selector: (item: T) => React.ReactNode | undefined;
}

export interface ITableHeaderCell<T extends IEntity> {
  column: ITableColumn<T>;
  order?: TableOrder;
  onClick: () => void;
}

export interface ITableRowCellProps<T extends IEntity> {
  column: ITableColumn<T>;
  row: T;
}

export interface ITableComponentProps<T extends IEntity> {
  columns: ITableColumn<T>[];
  rows: T[];

  pending?: boolean;

  initialPageSize?: number;
  totalRows?: number;
  pageSizes?: number[];
  onChangePage?: (page: number) => void;
  onChangePageSize?: (page: number) => void;

  initialOrder?: TableOrder;
  onChangeOrder?: (order?: TableOrder) => void;
}

const TableHeaderCell = <T extends IEntity>(props: ITableHeaderCell<T>): JSX.Element => {
  const { order, onClick } = props;
  const { id, label, isOrderable } = props.column;
  return (
    <th
      onClick={onClick}
      scope="col"
      className={classNames([
        isOrderable && "cursor-pointer",
        "sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
      ])}
    >
      <div className="flex justify-between">
        <div>{label}</div>
        {isOrderable && order?.property === id && order.direction === TableOrderDirection.ASC && <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5 inline-block" aria-hidden="true" />}
        {isOrderable && order?.property === id && order.direction === TableOrderDirection.DESC && <FontAwesomeIcon icon={faChevronUp} className="h-5 w-5 inline-block" aria-hidden="true" />}
      </div>
    </th >
  );
}

const TableRowCell = <T extends IEntity>(props: ITableRowCellProps<T>): JSX.Element => {
  const { column, row } = props;
  const isBold = column.isBold || column.id === 'id';
  return (
    <td
      className={classNames(
        isBold && 'font-medium',
        'border-b border-gray-200 whitespace-nowrap py-3 pl-3 pr-2 text-sm text-gray-900 sm:pl-6 lg:pl-8'
      )}
    >
      {column.selector(row)}
    </td>
  )
}


const TableComponent = <T extends IEntity>(props: ITableComponentProps<T>): JSX.Element => {
  const {
    columns, rows,
    pending,
    initialPageSize, totalRows, pageSizes, onChangePage, onChangePageSize,
    initialOrder, onChangeOrder,
  } = props;
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableOrder | undefined>(initialOrder);
  const [pageSize, setPageSize] = useState<number>(initialPageSize || 10);

  const onClickColumn = (column: ITableColumn<T>) => {
    if (!column.isOrderable) return;
    if (order?.property !== column.id) {
      setOrder({ property: column.id, direction: TableOrderDirection.ASC });
    }
    else {
      if (order.direction === TableOrderDirection.ASC)
        setOrder({ property: column.id, direction: TableOrderDirection.DESC });
      else
        setOrder(undefined);
    }
  }

  useEffect(() => {
    if (onChangeOrder) {
      onChangeOrder(order);
    }
  }, [order]);

  useEffect(() => {
    if (onChangePage) {
      onChangePage(page);
    }
  }, [page]);

  useEffect(() => {
    if (onChangePageSize) {
      onChangePageSize(pageSize);
    }
  }, [pageSize]);

  return (
    <div>
      <div className="w-full overflow-x-auto ">
        <table className={classNames(["min-w-full border-separate border-spacing-0 ", pending && 'opacity-25'])}>
          <thead className="sticky top-0">
            <tr>
              {columns.map(column => <TableHeaderCell key={column.id} column={column} order={order} onClick={() => onClickColumn(column)} />)}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => <tr key={i}>{columns.map((column, j) => <TableRowCell key={`${i}_${j}`} column={column} row={row} />)}</tr>)}
            {(rows.length === 0 && !pending) && <tr><td colSpan={columns.length}><Alert.Information title={t('common.noResults')} noClose></Alert.Information></td></tr>}
          </tbody>
        </table>
      </div>
      <Pagination
        page={page}
        total={totalRows || rows.length || 0}
        pageSize={pageSize}
        previousButton
        nextButton
        pageSizes={pageSizes}
        onSetPage={setPage}
        onSetPageSize={setPageSize}
        disabled={pending}
      />
    </div>
  )
}

export default TableComponent;