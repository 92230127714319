import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Instructor } from 'src/api/stable/Stable';
import BookingWorkingHours, { daysOfWeek } from 'src/components/Booking/BookingWorkingHours';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface WorkingHoursFormProps {
  form: IForm<Instructor>;
}

export default function WorkingHoursForm(props: WorkingHoursFormProps) {
  const { form } = props;
  const { t } = useTranslation();

  const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

  const dayOfWeekNumberToString = (dayOfWeek: number) => {
    switch (dayOfWeek) {
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      case 0:
        return 'Sunday';
      default:
        return 'Sunday';
    }
  }

  return (
    <FormSection
      title={t('stable.instructors.workingHours.title')}
      description={t('stable.instructors.workingHours.description')}
      full
    >
      <table className="max-w-full">
        {daysOfWeek
          .map((d, i) => <BookingWorkingHours
            key={i}
            dayOfWeek={d}
            value={{
              start: _.get(form.data.workingHours, `values.${dayOfWeekNumberToString(d)}`)?.start?.split(':').slice(0, 2).map(Number) || [8, 0],
              end: _.get(form.data.workingHours, `values.${dayOfWeekNumberToString(d)}`)?.end?.split(':').slice(0, 2).map(Number) || [16, 0]
            }}
            onChange={(value) => {
              const workingHours = form.data.workingHours || {
                values: {
                  "Monday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Tuesday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Wednesday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Thursday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Friday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Saturday": { start: '08:00:00.0000000', end: '16:00:00.0000000' },
                  "Sunday": { start: '08:00:00.0000000', end: '16:00:00.0000000' }
                }
              };
              const dCorrected = dayOfWeekNumberToString(d);
              _.set(workingHours, `values.${dCorrected}`, {
                start: `${zeroPad(value.start[0], 2)}:${zeroPad(value.start[1], 2)}:00.0000000`,
                end: `${zeroPad(value.end[0], 2)}:${zeroPad(value.end[1], 2)}:00.0000000`
              });

              form.setData({ ...form.data, workingHours: workingHours } as Instructor);
            }}
          />)}
      </table>
    </FormSection>
  )
}